.content {
    width: 100vw;
    max-width: 1000px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* background-color: red; */
    /* height: 200px;; */
}

@media (max-width: 599px) {
    .content {
        width: 100%;
        padding: 24px 8px;
    }
}

.header {
    font-weight: 800;
    font-size: 28px;
    text-align: center;
}

.description {
    color: var(--text-secondary-color);
    margin-top: 0px;
    font-size: 18px;
    text-align: center;
}

.plans {
    margin-top: 34px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* grid-template-columns: 1fr 1fr; */
    column-gap: 16px;
    row-gap: 16px;
    width: 100%;
    flex-wrap: wrap;
    /* max-width: 900px; */
}

@media (max-width: 599px) {
    .plans {
        flex-direction: column-reverse;
    }
}

.pricingPlan {
    padding: 40px 32px 30px 32px;
    border: 1px #ebeaea solid;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    max-width: 400px;
}

@media (max-width: 599px) {
    .pricingPlan {
        max-width: 100%;
    }
}

.pricingHeader {
    font-weight: 700;
    font-size: 18px;
}

.pricing {
    margin-top: 12px;   
}

.price {
    font-size: 38px;
    font-weight: 800;
}

.oldPrice {
    font-size: 16px;
    margin-right: 8px;
    /* font-weight: 600; */
    color: #6a6a6a;
    text-decoration: line-through;
}

.planTime {
    font-weight: 700;
    font-size: 18px;
}

.badge {
    padding: 4px 12px;
    background-color: var(--primary);
    color: white;
    font-weight: 600;
    font-size: 12px;
    border-radius: 15px;
    text-transform: uppercase;
    width: auto;
    position: absolute;
    top: -12px;
}

.planDescription {
    margin-top: 16px;
    font-size: 16px;;
    color: var(--text-secondary-color);
}

.accessButton {
    font-weight: 700;
    /* width: 100%; */
    align-self: center;
    border-radius: 10px;
    margin-top: auto;
    width: 80%;
}

.notPopularButton {
    composes: accessButton;
    background-color: var(--primary-contrast-light);
    color: var(--primary);
    border-color: var(--primary-contrast-light);
}

.perks {
    margin-top: 24px;
    margin-bottom: 30px;
    display: grid;
    row-gap: 15px;
}

.perkContainer {
    /* margin-left: 20px; */
    display: flex;
    flex-direction: row;
    
}

.perk {
    margin-left: 10px;
    font-size: 15px;
    margin-top: -3px;
}

.perkCheckmark {
    color: var(--primary);
    font-weight: 900;
    font-size: 24px;
}

.boldedPerk {
    font-weight: 700;
}

/* 
.perkTooltip {
    background-color: var(--primary);
    color: white;
    font-weight: 600;
} */

.underlinedLink{
    font-weight: 700;
    text-decoration: underline;
    text-decoration-color: var(--primary);
    text-decoration-style: dotted;
    cursor: pointer;
  }

  .billingCycle{
      margin-left: 6px;
      font-size: 12px;
      /* color: var(--text-secondary-color); */
  }