

.container {
    color: var(--text-secondary-color);
    padding-bottom: 20px;
}

.sectionsContainer {
    margin-top: 0px;
    display: grid;
    gap: 18px;
}

.sideBySide {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sideBySide > * {
    margin-right: 16px;
}

.label {
    font-weight: 600;
    margin-right: 12px;
    margin-bottom: 8px;
    color: var(--text-secondary-color);
}

.setting {
    margin-top: 20px;
}

.threeItemsSetting {
    display: flex;
    flex-wrap: wrap;
    /* max-width: 400px; */
}

.threeItemsSetting  > * {
    margin-right: 12px;
}

.subHeader {
    font-weight: 700;
    color: var(--dark-black);
    margin-bottom: 14px;
}

.input {
    /* height: 40px; */
    width: 60px;
}