.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 24px 24px 24px;
    font-size: 16px;
}

@media (max-width: 599px) {
    .container {
        padding: 40px 12px 20px 12px;
    }
}

.header {
    font-weight: 800;
    font-size: 24px;
    line-height: 1.4;
    text-align: center;
}

.subHeader {
    margin-top: 14px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    /* color: var(--text-secondary-color); */
} 

.askingAsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -4px;
    font-size: 14px;
    /* color: var(--text-secondary-color); */
}

.askingAsContainer div {
    margin-left: 8px;
}

.askingAsContainer span {
    font-weight: 600;
}

.form { 
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}

.bubblesContainer {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
}

.leftAvatar {
    margin-right: -8px;
}


.avatar {
    border: 1px solid var(--kinky-grey);
}

.textarea {
    width: 100%;
    min-height: 120px !important;
}

.button {
    width: 100%;
}


.reasonHeader {
    font-weight: 700;
    font-weight: 18px;
    text-align: center;
    color: var(--dark-purple);
}

.reasonSubHeader {
    margin-top: 2px;
    text-align: center;
    margin-bottom: 18px;
    font-size: 15px;
    color: var(--text-secondary-color);
} 

.doneAnimation {
    margin-top: -20px;
    width: 150px;
    height: 120px;
}