.container {
    margin-top: 70px;
}

.questionsContainer {
    margin-top: 28px;
    margin-bottom: 20px;
    display: grid;
    row-gap: 16px;
    column-gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 599px) {
    .questionsContainer {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 599px) {
    .questionsContainer {
        margin-right: 0px;
    }
}

.question {
    min-height: 130px;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
}

.title {
    font-weight: 600;
    line-height: 1.4;
}

.askedByContainer {
    display: flex;
    margin-top: auto;
    flex-direction: row;
    align-items: center;
}

.askedByContainer div {
    margin-left: 8px;
    font-size: 12px;
    color: var(--text-secondary-color);
}