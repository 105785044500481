.container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    line-height: 1.6;
}


.content {
    background: white;
    border-radius: 5px;
    width: 100%;
    /* padding: 24px 24px 0px 24px; */
    margin: 12px;
    text-align: center;
    line-height: 1.6;
}


.header {
    /* margin-top: 20px; */
    font-size: 20px;
    font-weight: 700;
}

.subHeader {
    margin-top: 4px;
    color: var(--text-secondary-color);
}

.rate {
    margin-top: 30px;
    transform: scale(2);
    padding-right: 30px;
    padding-left: 30px;
}


.didNotMetButton {
    margin-top: 50px;
}

.phaseContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 10px 0px 40px 0px; */
}

.ratePhase {
    
}

.meetingNotesForm {
    margin-top: 20px;
    width: 100%;
}

.meetingNotesForm textarea {
    width: 100%;
}

.notesDoneButton {

}

.feedbackGuide {
    margin-top: 50px;
    background-color: #fff3cc;
    color: #000000c2;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    font-weight: 600;
    border-radius: 5px;
}

.starIcon {
    margin-right: 3px;
    transform: scale(0.8);
}
