
.itemContainer {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 165px;
    position: relative;
    width: 100%;
    max-width: 520px;
    align-items: flex-start;
}

@media (max-width: 599px) {
    .itemContainer {
        height: auto;
        /* padding: 20px 12px 20px 12px; */
    }
}

.itemHeader {
    font-size: 18px;
    line-height: 1.4;
    font-weight: bold;
}

.itemSubHeader {
    margin-top: 10px;
    color: var(--text-secondary-color);
    line-height: 1.5;
    /* max-width: 85%; */
    font-size: 15px;
}

.badge {
    padding: 6px 8px;
    height: 20px;
    font-weight: 600;
    background-color: #f6f6f8;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    position: absolute;
    bottom: 20px;
    text-transform: uppercase;
}

@media (max-width: 599px) {
    .badge {
        position: static;
        margin-top: 30px;
        width: fit-content;
    }
}

.subTitle {
    display: flex;
    align-items: center;
}

.icon {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 80px;
}