
.container {
    width: 100%;
    padding: 18px 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    background-color: var(--primary) !important;
    border-radius: 5px;
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.text {
    font-size: 16px;
    font-weight: 700;
}


