.container {
    width: 100%;
}

.top {
    padding: 0 48px;
    display: flex;
    height: 120px;
    align-items: flex-end;
    background-color: var(--primary);
}

@media(max-width: 599px) {
    .top {
        padding: 30px 12px;
        flex-direction: column;
        height: auto;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

.memberName {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0.45px;
    color: #ffffff;
}

.memberTitle {
    font-size: 16px;
    margin-top: 5px;
    line-height: 1.4;
    color: #ffffff;
}


.avatarContainer     {
    margin-bottom: -50px;
    margin-right: 24px;
}

@media(max-width: 599px) {
    .avatarContainer {
        margin-bottom: 20px;
        margin-right: 0px;
    }
}

.avatar {
    border-width: 4px;
}

.userDetailsContainer {
    display: flex;
    flex-direction: column;;
    margin-bottom: 20px;
}

@media(max-width: 599px) {
    .userDetailsContainer {
        margin-bottom: 0px;
    }
}