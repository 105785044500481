

.container {
    max-width: var(--max-width-default);

}

.tabs {
    margin-top: 30px;
}

.header {
    color: var(--dark-black);
    margin-bottom: 2px;
    font-size: 18px;
    font-weight: 700;
}

.description {
    color: var(--text-secondary-color);
    margin-top: 4px;
    font-size: 15px;
    font-weight: 400;
}

