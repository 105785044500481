.container {
    display: flex;
    flex-direction: column;
    padding: 50px;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    background: url('../stage/bg.jpg') repeat;

}


@media (max-width: 599px) {
    .container {
        margin-top: 50px;
        padding: 30px 10px 30px 10px;
        height: 100%;
    }
}