.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
}

.colored {
    padding: 20px 12px;
    border-radius: 5px;
    background-color: #fbfcff;
    border: 1px solid #f2f2f2;
}

.texts {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.nameContainer {
    display: flex;
    align-items: center;
}

.name {
    padding-bottom: 0px;
    color: var(--headers-color);
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 0px !important;
}

.host {
    margin-top: -3px;
    margin-left: 6px;
    font-size: 10px;
    border-radius: 5px;
    padding: 2px 6px 2px 6px;
    background-color: var(--primary);
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    padding-bottom: 2px;
    margin-top: 4px;
    line-height: 1.4;
    color: var(--text-secondary-color);
    font-size: 12px;
    margin-bottom: 0px !important;
}
