.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
}

.stickyBarOffset {
    height: 150px;
}

.content {
    padding: 8px;
}

.stickyBar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 12px;
    position: fixed;
    display: flex;
    width: 100%;
    bottom: 0px;
    left: 0px;
    box-shadow: 0px -4px 10px rgba(211, 211, 211, 0.5);
    background-color: white;
}

.stickyBarContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.doneButton {
    /* width: 100%; */
    width: 300px;
    height: 50px;
    font-weight: 700;
    border-radius: 5px;
}

.header {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 1.4;
    margin-top: 40px;
}

.bubblesContainer {
    margin: 24px 0px 20px 0px;
    display: flex;
    flex-direction: row;
}

.userAvatar {
    margin-right: -8px;
}

.title {
    font-size: 22px;
    font-weight: 700;
    color: var(--dark-purple);
}

.icons {
    margin: 10px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    color: var(--text-secondary-color);
}

.icon {
    margin-right: 2px;
}


.subTitle {
    margin-top: 12px;
    color: var(--dark-purple);
    font-size: 16px;
    font-weight: 600;
}

.guide {
    font-size: 18px;
    /* font-weight: 600; */
    color: var(--dark-grey);
    text-align: center;
    margin-bottom: 18px;
}

.selectedDatesNumber {
    /* color: var(--primary); */
    font-weight: 700;
}

.calendar {
    margin-top: 14px;
    height: auto;
    border: 1px solid rgb(224, 229, 247);
    border-radius: 5px 5px 0px 0px;
    /* ;max-width: 590px; */
    width: 100%;
    padding: 0;
    position: relative;
}

.calendarBottom  {
    width: 100%;
    background-color: #f6f6f7;
    border-top: 1px solid #dadcec;
    color: #5b5b5b;
    padding: 8px;
    font-size: 14px;
}

.calendarContent {
    padding: 12px 34px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 599px) {
    .calendarContent {
        padding: 12px 20px;
        flex-direction: column;
    }
}

.dayColumn {
    display: flex;
    flex-direction: column;
    padding: 0px 8px;
}


@media (max-width: 599px) {
    .dayColumn {
        margin-top: 12px;
    }
}

.dayName {
    font-weight: 800;
    font-size: 15px;
}

.dayDate {
    margin-top: 2px;
    color: var(--text-secondary-color);
    font-size: 12px;
    font-weight: 400;
}

.timesColumn {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
}

.timeOption {
    transition: all 0.2s ease 0s;
    width: 66px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    padding: 1px 12px;
    white-space: nowrap;
    user-select: none;
    font-size: 16px;
    color: var(--text-secondary-color);
    border: 1px solid var(--easy-grey);
    margin-bottom: 12px;
}


@media (max-width: 599px) {
    .timeOption {
        width: 100%;
    }
}

.timeOption:hover{
    background-color: var(--primary-contrast-light);
}

.timeOptionSelected {
    background-color: var(--primary);
    color: white;
}

.timeOptionSelected:hover {
    background-color: var(--primary);
}

.timezone {
    margin-top: 8px;
    font-size: 13px;
    color: var(--text-secondary-color);
}

.nextWeekButton {
    position: absolute;
    right: 14px;
    top: 14px;
    cursor: pointer;
}

.previousWeekButton {
    composes: nextWeekButton;
    left: 14px;
    right: unset;
}

.weekText {
    display: none;
    font-size: 12px;
}

@media (max-width: 599px) {
    .weekText {
        padding: 0px 4px;
        display:initial;
    }
}

.undelineDercoration {
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: #b5b5b5;
    text-decoration-style: dotted;
    cursor: pointer;
}

.timezonesTooltip {
    font-size: 14px;
}