.container {
    justify-content: center;
    align-items: center;
    padding: 4px 8px 4px 8px;
    font-size: 12px;
    background-color: var(--red);
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    color: white;
    flex-grow: 0;
    width: auto;
}

@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }

.circle {
    width: 5px;
    height: 5px;
    background: white;
    border-radius: 50%;
    margin-right: 5px;
    animation: flickerAnimation 2s infinite;
}