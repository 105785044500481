.container {
    padding: 30px 30px;
    margin: 0 8px;
    width: 100%;
    max-width: 500px;
    background-color: #f6f7fa;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

@media (max-width: 599px) {
    .container {
        padding: 30px 8px;
        text-align: center;
    }
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .content {
        flex-direction: column;
    }
}

.textContainer {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    z-index: 99;
}

@media (max-width: 599px) {
    .textContainer {
        margin-left: 0px;
        margin-top: 12px;
    }
}

.header {
    color: black;
    font-size: 20px;
    font-weight: 700;
}

.secondaryText {
    color: #181d26;
    margin-top: 4px;
    font-size: 14px;
    font-weight: 600px;
}

.boardingPassIcon {
    position: absolute;
    width: 200px;
    right: -80px;
    bottom: 0px;
    transform: rotateY(180deg);
    z-index: 1;
}

@media (max-width: 599px) {
    .boardingPassIcon {
        width: 120px;
        right: -40px;

    }
}