.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.header {
    font-weight: 800;
    font-size: 28px;
    line-height: 1.4;
    text-align: center;
}

.subHeader {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    color: var(--text-secondary-color);
}

.calendarAnimation {
    width: 200px;
    margin-bottom: 30px;
}

.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.actionButtonsContainer {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
}

.actionButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 24px;
    cursor: pointer;
    user-select: none; 
    border: 1px #d8d8d8 solid;
    transition: ease 0.4s;
}

.actionButton:hover {
    transform: scale(1.02);
}

.actionButton img {
    width: 80px;
    margin-bottom: 12px;
}

.actionButton div {
    font-weight: 700;
    text-align: center;
}