.container {}

.strip {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* max-width: 1000px; */
}

@media (max-width: 599px) {
    .strip {
        display: grid;
        flex-direction: column;
    }
}

.strip>* {
    margin-bottom: 16px;
    margin-right: 16px;
}

.itemContainer {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 165px;
    position: relative;
    overflow: hidden;
    padding: 20px;
    width: 100%;
    max-width: 520px;
    align-items: flex-start;
}

@media (max-width: 599px) {
    .itemContainer {
        height: auto;
        padding: 20px 12px 20px 12px;
    }
}

.itemHeader {
    font-size: 18px;
    line-height: 1.4;
    font-weight: bold;
}

.itemSubHeader {
    margin-top: 10px;
    color: var(--text-secondary-color);
    line-height: 1.5;
    max-width: 85%;
    font-size: 15px;
}

.sosImage {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 70px;
}

.sosReportImage {
    position: absolute;
    right: -40px;
    bottom: -20px;
    width: 135px;
}

@media (max-width: 599px) {
    .sosImage {
        width: 80px;
    }
    .sosReportImage {
        position: absolute;
        right: -35px;
        bottom: -20px;
        width: 120px;
    }
}

.badge {
    padding: 3px 5px;
    height: 20px;
    background-color: #01c479;
    color: white;
    font-weight: 600;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    position: absolute;
    bottom: 20px;
    text-transform: uppercase;
}

@media (max-width: 599px) {
    .badge {
        position: static;
        margin-top: 30px;
    }
}

.phocuswrightLogo {
    height: 22px;
    margin-top: -4px;
    margin-left: 5px;
}

.subTitle {
    display: flex;
    align-items: center;
}