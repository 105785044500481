
.speaker {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.speakerAvatar {
    border: 2px var(--primary) solid;
}

.speakerRole {
    margin-top: 5px;
    font-size: 11px;
    color: #a9acb0;
    text-align: center;
    text-transform: capitalize;
}
