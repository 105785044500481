.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.title {
    font-weight: 800;
    font-size: 19px;
    line-height: 1.4;
    text-align: center;
}

.subTitle {
    margin-top: 8px;
    text-align: center;
    color: var(--text-secondary-color);
}

.avatarsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 40px;
    margin-bottom: 50px;
    width: 300px;
}

.avatar {
    
}

.cancelButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.countdownText {
    color: var(--text-secondary-color);
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
}