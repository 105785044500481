

.container {
    color: var(--text-secondary-color);
}

.generateButton {
    margin-top: 20px;
}

.linkGeneratedModal {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.linkGeneratedModal .header {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
}

.linkGeneratedModal .subHeader {
    font-size: 16px;
    color: var(--text-secondary-color);
}

.input {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

:global .guest-invite-modal .ant-select-selection-item {
    padding-top: 10px !important;
}

:global .guest-invite-modal .ant-select-selection-placeholder {
    padding-top: 10px !important;
}
