.container {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

@media (max-width: 599px) {
    .container {
        height: 100%;
    }
}

.iframe {
    border: 0;
    width: 100%;
    height: 100%;
}

.helpBox {
    background-color: white;
    width: 200px;
    height: 100px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);
    position: 0;
    bottom: 20px;
    left: 20px;
    z-index: 4;
    position: absolute;
}

.watermark {
    padding: 8px 70px;
    background-color: rgba(255, 255, 255, 0.981);
    width: auto;
    height: 48px;
    border-radius: 5px;
    opacity: 1;
    display: flex;
    align-items: center;
    user-select: none;
}

.logo {
    margin-top: -4px;
    /* height: 28px; */
    width: 140px;
}

.title {
    font-weight: 500;
    margin-top: 2px;
    margin-left: 4px;
    opacity: 0.7;
    font-size: 15px;
}

.buttonsContainer {
    margin-top: 12px;
}

.startSessionButton {
    background: linear-gradient(1deg, rgb(255, 248, 57) 0%, rgb(255, 167, 85) 100%);
    font-size: 15px;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.927);
    font-weight: 700;
    cursor: pointer;
    border: none;
    user-select: none;
    opacity: 1;
    transition: 0.3s;
}

.startSessionButton:hover {
    background: linear-gradient(1deg, rgb(255, 248, 57) 0%, rgb(255, 167, 85) 100%);
    color: rgba(0, 0, 0, 0.927);
    opacity: 0.8;
}

.endSessionButton {
    border: none;
    background: linear-gradient(1deg, rgb(255, 57, 57) 0%, rgb(194, 0, 0) 100%);
    font-size: 15px;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    opacity: 1;
    transition: 0.5s;
}

.endSessionButton:hover {
    background: linear-gradient(1deg, rgb(255, 57, 57) 0%, rgb(194, 0, 0) 100%);
    opacity: 0.8;
}

.floating {
    position: absolute;
    left: 8px;
    top: 8px;
}

.endButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .endButtonContainer {
        display: none;
    }
}

.eventLiveContainer {
    display: flex;
    flex-direction: row;
    color: white;
    font-weight: 600;
    font-size: 12px;
    margin-left: 12px;
    align-items: center;
}

.liveBubble {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgb(1, 222, 119);
    margin-right: 6px;
}
