.container {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;    
}

.texts {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    justify-content: center;
}

.titleContainer {
    display: flex;
    align-items: center;
}

.name {
    font-weight: 600;
    color: var(--dark-black);
    font-size: 14px;
}

.time {
    margin-left: 6px;
    font-size: 12px;
    color: #b0b0b0;
}

.message {
    margin-top: 2px;
    font-size: 14px;
    color: #053737f0;
    background-color: var(--primary-contrast-light);
    padding: 8px;
    border-radius: 10px;
    line-height: 1.6;
    width: fit-content;
}

.avatar {
    display: flex;
    justify-content: center;
    margin-top: 2px;
}

.host {
    margin-left:6px;
    font-size: 10px;
    color:var(--primary);
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}