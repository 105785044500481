.container {
    display: flex;
    user-select: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 304px;
    padding: 12px;
    background: #1f1e2592;
    position: relative;
    border-radius: 5px;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.container:hover {
    background: #1f1e25bd;
}

.container:hover>.buttonContainer {
    background-color: #3c3946;
}

.adminContainer:hover>.top .tableNumber {
    display: none;
}

.adminContainer:hover>.top .editButton {
    display: block;
}

.editButton {
    cursor: pointer;
    display: none;
}

.top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    color: #9592a3;
    top: 16px;
    left: 16px;
    right: 16px;
    font-weight: 600;
}

.userTableIndicator {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.userTableIndicatorText {
    margin-bottom: 0px !important;
    height: 15px;
    color: initial;
    width: 200px;
    text-align: right;
}

.userAvatar {
    margin-left: 8px;
    border: 1px #9592a3 solid;
}

.tableContainer {
    margin-top: -16px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: scale(1.15);
}

.table {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: black;
    opacity: 1;
    border-radius: 16px;
    width: 120px;
    height: 120px;
    color: white;
    text-align: center;
    font-weight: 600;
    padding: 12px;
    line-height: 1.5;
    font-size: 11px;
    box-shadow: rgba(21, 21, 25, 0.708) 0px 10px 40px;
    position: relative;
}

.buttonContainer {
    position: absolute;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    bottom: 8px;
    left: 8px;
    right: 8px;
    height: 44px;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.joinButton {
    border-radius: 8px;
    height: 100%;
    font-size: 15px;
    color: #9592a3;
    width: 100%;
    background: none;
    border: none;
    font-weight: 600;
}

.joinButton:hover {
    color: #9592a3;
    background: none;
}

.joinButton:focus {
    color: #9592a3;
    background: none;
}

.full {
    color: #ff9d9d;
    font-weight: 700;
} 

.chairCoupleRightLeft {
    display: flex;
    flex-direction: column;    
    justify-content: space-around;
    align-items: center;
    position: absolute;
    height: 80%;
}

.chairCoupleRight {
    right: -16px;
}

.chairCoupleLeft {
    left: -16px;
}


.chairCoupleTopBottom {
    display: flex;
    flex-direction: row;    
    justify-content: space-around;
    position: absolute;
    width: 80%;
}

.chairCoupleBottom {
    bottom: -16px;
}

.chairCoupleTop {
    top: -16px;
}


.chairContainer {
    display: flex;
    justify-self: center;
    align-items: center;
    position: relative;
}


.chair {
    background-color: rgb(60, 57, 70);
    border: 1.5px solid #373643;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}


.chairAvatar {
    cursor: pointer;
    position: absolute;
    border-radius: 5px;
    border: 1px solid #4a4959;
}

.preselectedTable {
    background: #1cbdc030;
}

.preselectedTable:hover {
    background: #1cbdc045;
}

.preselectedTable:hover>.buttonContainer {
    background-color: #004d4d46;
    border: 1px solid #ffffff58; 
    font-weight: 600;
}

