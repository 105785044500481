.container {
    max-width: var(--max-width-default);
}


.content {
    margin-top: 34px;
}

.timeframeButton {
    color: var(--dark-black);
    border: unset;
    margin-bottom: 16px;
    padding-left: 0px;
    font-weight: 600;
}

.topSection {
    display: flex;
}

.topSection .left {
    display: grid;
    flex-direction: column;
    grid-template-rows: 1fr 1fr 1fr;
    row-gap: 12px;
}
/* .topSection .left > * {
    margin-bottom: 12px;
} */

@media (max-width: 599px) {
    .topSection {
        flex-direction: column;
    }
}

.membersChart {
 margin-left: 14px;
 height: 360px;
 width: 100%;
 /* max-width: 100%; */
 padding: 20px 24px 12px 12px;
}

@media (max-width: 599px) {
    .membersChart {
        margin-left: 0px;
        margin-top: 12px;
    }
}


.metricsCardContainer {
    width: 300px;
    flex-shrink: 0;
}

@media (max-width: 599px) {
    .metricsCardContainer {
        width: 100%;
    }
}


.metricsTitle {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
}

.metricsNumberContainer {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: flex-end;
}

.metricsNumber {
    margin-right: 12px;
    font-size: 34px;
    font-weight: 700;
    color: var(--dark-black);
}

.bubble {
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.greenBubble {
    color: #2f6f5b;
    background-color: #def7ec;
}

.redBubble {
    color: #9e2827;
    background-color: #fce8e6;
}

.icon {
    font-size: 12px;
    font-weight: 700;
    color: #0f9f6e;
    margin-right: 4px;
}

.iconRed {
    composes: icon;
    color: #f05252;
}

.secondStrip {
    margin-top: 24px;
}

@media (max-width: 599px) {
    .secondStrip {
       margin-top: 42px;
    }
}

.grid {
    margin-top: 16px;
    display: grid;   
    grid-template-columns: 1fr 1fr;
    gap: 14px;
}

@media (max-width: 599px) {
    .grid {
        grid-template-columns: 1fr;
    }
}

.subHeader {
    font-weight: 700;
    margin-bottom: 12px;
}


.chartContainer {
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filterChartHeader {
    display: flex;
    align-items: center;
    font-weight: 700;

}

.filterChartHeader > * {
    margin-right: 8px;
}