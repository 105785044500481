.container {
    width: 100%;
    max-width: 400px;
    /* height: 200px; */
    line-height: 1.4;
    padding: 0px;
    border-radius: 10px;
    overflow: hidden;
    user-select: none;
}

.participantInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 26px;
    cursor: pointer;
    justify-content: center;
}

.participantInfo .texts {
    width: 100%;
}

.name {
    font-size: 18px;
    font-weight: 700;
}

.title {
    color: var(--text-secondary-color);
    margin-top: 6px;
    font-size: 14px;
}

.actions {
    height: 57px;
}

.horizontalDivider {
    width: 100%;
    height: 1px;
    background-color: var(--easy-grey);
}

.verticalDivider {
    height: 100%;
    width: 1px;
    margin-right: 2px;
    background-color: var(--easy-grey);
}

.buttons {
    display: flex;
    width: 100%;
    height: 100%;
}

.buttonDisabled {
    color: #d2d2d2 !important;
}

.button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: var(--dark-grey);
    height: 100%;
    border: none;
    cursor: pointer;
    font-size: 15px;
}

.icon {
    color: #9ea6b2;
    margin-right: 14px;
    margin-top: 2px;
}

.notesHeader {
    font-weight: bold;
    margin-bottom: 12px;
}

.topBar {
    padding: 12px 18px 0px 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.infoIcon {
    margin-left: 2px;
    margin-bottom: 0px;
}

.date {
    color: var(--text-secondary-color);
    font-size: 12px;
    cursor: pointer;
}

.feedbackScore {
    font-size: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.scoreText {
    font-weight: bolder;
    font-size: 14px;
    color: #997606;
}

.starIcon {
    color: #ffcd12;
    font-size: 16px;
}

.didntHappen {
    font-weight: 700;
    font-size: 12px;
}

.upcomingMeeting {
    font-size: 12px;
    color: var(--primary);
    font-weight: 600;
}
