
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 270px;

}

.logo {
    width: 100%;
}

.phocuswright {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.phocuswright img {
    width: 100px;
}

.phocuswright span {
    margin-top: 1px;
    margin-right: 4px;
    font-size: 14px;
    font-weight: 300;
    color: #113a4e;
}