.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.title {
    font-weight: 800;
    font-size: 19px;
    line-height: 1.4;
    text-align: center;
}

.subTitle {
    margin-top: 8px;
    text-align: center;
    color: var(--text-secondary-color);
}

.loader {
    margin: 30px 0px;
    width: 100%;
    height: 250px;
}


