.container {
    position: relative;
    padding-bottom: 40px;
}

.eventsGrid {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
}

.sectionContainer {
    display: flex;
    flex-direction: column;
}

.eventsGrid>* {
    margin-right: 15px;
    margin-bottom: 15px;
}

@media(max-width: 599px) {
    .eventsGrid>* {
        margin-right: 0px;
        margin-bottom: 16px;
        width: 100%;
    }
}

.eventsContainer {
    margin-top: 60px;
}

.eventBox {
    width: 380px;
}

@media (max-width: 599px) {
    .eventBox {
        width: 100%;
    }
}

.buttons {
    margin-top: 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.buttons>* {
    margin-right: 8px;
}

.filterButton {
    background-color: transparent;
    color: var(--darker-black);
    border-color: var(--darker-black);
}

.filterButtonSelected {
    background-color: var(--darker-black) !important;
    border-color: var(--darker-black) !important;
    color: white !important;
}

.filterButton {
    background-color: transparent;
    color: var(--darker-black);
    border-color: var(--darker-black);
}

.divider {
    background: #dfdfdf;
    width: 1px;
    margin-right: 20px;
    margin-left: 12px;
}

@media (max-width: 599px) {
    .buttons > * {
        margin-bottom: 8px;
    }
}

.futureEventsGrid {
    margin-top: 40px;
    display: grid;
    row-gap: 24px;
}

.dateDivider {
    height: 1px;
    background-color: #e0e9f4;
    width: 100%;
    max-width: 400px;
    margin: 12px 0px;
}

.dateHeader {
    font-weight: 700;
    font-size: 34px;
}

.dateHeaderToday {
    font-weight: 700;
    font-size: 24px; 
}

.dayInTheWeek {
    margin-top: 8px;
    color: var(--text-secondary-color);
    font-size: 15px;
}

.momthName {
    margin-left: 4px;
    font-size: 16px;
    font-weight: 700;
}

.emptyEvents {
    margin-top: 30px;
    border: 1px solid var(--kinky-grey);
    padding: 20px;
    max-width: 350px;
    background-color: #ffffff;
    color: var(--text-secondary-color);
    font-size: 15px;
}