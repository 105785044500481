.hide { 
    display: none;
}

.small {
    width: 40px;
    height: 40px;
}

.medium {
    width: 60px;
    height: 60px;
}

.large {
    width: 100px;
    height: 100px;
}