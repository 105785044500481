.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.theme {
    padding: 5px 8px;
    border-radius: 2px;
    font-size: 12px;
    color: white;
    margin-right: 8px;
    font-weight: 600;
}
