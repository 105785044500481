.container {
    height: 100%;
    width: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px 20px 20px;
    height: 100%;
    width: 100%;
    text-align: center;
}

.logo {
    height: 100px;
    transform: scale(0.7);
}

.header {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 16px;
}

.description {
    color: var(--text-secondary-color);
    font-size: 16px;
}

.planName {
    font-weight: 700;
}

.changePlan {
    color: var(--primary);
    margin-left: 8px;
    font-weight: 700;
    cursor: pointer;
}

.iframeWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0px 10px 0px;
    max-width: 1000px;
    width: 100%;
    height: 100%;
}

.loader {
    position: absolute;
}

.shareContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
}

.shareHeader {
    font-size: 14px;
    /* font-weight: 700; */
    border: var(--material-color-grey-200) 1px solid;
    padding: 8px;
    color: var(--text-secondary-color);
}

.iframe {
    width: 100%;
    height: 100%;
    min-height: 520px;
    border: none;
}

.payingUser {
    color: var(--text-secondary-color);
    font-size: 16px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
}

.userName {
    font-weight: 700;
    margin-left: 4px;
}


.shareLink {
    font-weight: 600;
    color: var(--primary);
    cursor: pointer;
}

.smallLetters {
    font-size: 14px;
    color: var(--text-secondary-color);
    text-align: center;
    font-weight: 600;
}