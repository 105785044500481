.container {
    display: flex;
    min-height: 100vh;
    width: 100%;
}

@media (max-width: 599px) {
    .container {
        height: auto;
    }
}

.content{ 
    position: relative;
    flex: auto;
    background: url('../../common/images/videobg.jpeg') no-repeat;
    background-size: auto 200%;;
    height: 100%;
}

@media (max-width: 599px) {
    .content {
    }
}

.sider {
    flex-shrink: 0;
    background-color: white;
    height: 100vh;
    /* position: fixed;
    right: 0; */
    z-index: 10;
    border-left: 1px solid var(--kinky-grey);
    padding: 20px;

}

@media(max-width: 599px) {
    .sider {
        position: fixed;
        height: 100vh;
        right: 0px;
    }
}


.loadingContainer {
    color: white;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.loadingHeader {
    margin-top: 14px;
    font-size: 18px;
    font-weight: 600;
}

