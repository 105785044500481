.container {
    display: flex;
    flex-direction: column;
}

.header {
    margin-top: 44px;
    font-size: 18px;
    font-weight: bold;
}

.description {
    font-size: 15px;
    margin-top: 12px;
}

.capabilitiesGrid {
    margin-top: 24px;
    display: grid;
    row-gap: 20px;
}

.capability {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding: 12px; */
    /* border: 1px var(--text-secondary-color) solid; */
}

.capabilityIcon {
    height: 24px;
    
}

.capabilityTexts {
    margin-left: 25px;
}

.capabilityHeader {
    font-weight: 600;
    font-size: 16px;
    color: var(--dark-black);
}

.capabilityDescription {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: var(--text-secondary-color);
}