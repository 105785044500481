.headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.header {
    font-size: 38px;
    font-weight: 800;
    line-height: 1.4;
}

@media(max-width: 599px) {
    .header {
        font-size: 28px;
    }
}



.subTitle {
    margin-top: 12px;
    color: var(--text-secondary-color);
    font-size: 18px;
    max-width: 500px;
}
