

.container {
    color: var(--text-secondary-color);
}

.content {
    margin-top: 30px;
    display: grid;
    gap: 30px;
}

.smallHeader {
    font-weight: 700;
    margin-bottom: 10px;
    color: var(--dark-black);
}

.text {
    color: var(--text-secondary-color);
    font-weight: 600;
}

.button {
    width: fit-content;
}