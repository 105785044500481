.attendeeOption {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.attendeeAvatar {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    margin-left: 8px;
    flex-shrink: 0;
}

.attendeeName {
    color: var(--text-secondary-color);
    font-size: 14px;
    color: var(--dark-black);
    margin-right: 8px;
}

.userSelect {
    width: 100%;
    height: auto;
}

:global .edit-round-table-modal .ant-select-show-search.ant-select-multiple .ant-select-selector {
    height: auto;
    min-height: 60px;
}
:global .edit-round-table-modal .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    height: auto;
    font-weight: 600;
    padding: 4px !important;
    border-radius: 4px;
    border-color: rgb(220, 220, 220);
    background-color:#f2f2f2;
}