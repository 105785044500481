.container {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}


.content {
    background: white;
    border-radius: 5px;
    width: 100%;
    max-width: 800px;
    padding: 24px 24px 0px 24px;
    margin: 12px;
    text-align: center;
    line-height: 1.6;
}


.header {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
}

.subHeader {
    margin-top: 4px;
    color: var(--text-secondary-color);
}

.rate {
    margin-top: 30px;
    transform: scale(2);
}


.didNotMetButton {
    margin-top: 50px;
}

.phaseContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding: 10px 0px 40px 0px; */
}

.ratePhase {
    height: 340px;
}

.meetingNotesForm {
    margin-top: 20px;
    width: 100%;
}

.meetingNotesForm textarea {
    width: 80%;
}

.notesDoneButton {

}