.container {
    line-height: 1.5715;
}

.attendees>* {
    margin-bottom: 20px;
}

.attendees {
    padding: 16px 10px;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    flex-grow: 1;
    max-height: calc(100vh - 60px);
}

.attendees::-webkit-scrollbar {
    display: none;
}

.attendeeContainer {
    display: flex;
    align-items: center;
    user-select: none;
    cursor: pointer;

}

.texts {
    margin-left: 8px;
    margin-right: 12px;
}

.attendeeName {
    display: flex;
    flex-direction: row;
    font-size: 15;
    font-weight: 600;
    align-items: center;
}

.badges {
    margin-left: 6px;
    display: flex;
}

.badges>* {
    margin-right: 4px;
}

.youBadge {
    padding: 0px 4px;
    font-size: 9px;
    background-color: var(--ok-green);
    border-radius: 4px;
    color: white;
}

.speakerBadge {
    composes: youBadge;
    background-color: #d00061;
    color: white;
}

.hostBadge {
    composes: youBadge;
    background-color: var(--primary);
    color: white;
}

.firstTimerBadge {
    /* composes: youBadge; */
    font-size: 9px;

    /* background-color: #d0b800; */
    color:  #d0b800;
}

.attendeeTitle {
    font-size: 12px;
    /* margin-top: 4px; */
    color: var(--text-secondary-color);
}

.buttonsContainer {
    margin-top: 20px;
    display: grid;
    row-gap: 40px
}

.smallInfoText {
    font-size: 12px;
    color: var(--text-secondary-color);
}

.className::-webkit-scrollbar {
    display: none;
}

.siderHeader {
    display: flex;
    align-items: center;
}

.attendeesCollapsed {
    padding: 10px 0px;
    overflow: scroll;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100vh - 100px);
}

.attendeeContainerCollapsed {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
}

@media (max-width) {
    .attendeeContainerCollapsed {
        transform: initial;
    }
}

.enblePrivateTalksContainer {
    margin-top: 20px;
}

.externalLinksContainer {
    margin-top: 40px;
}

.header {
    font-weight: 700;
    color: #00063c;
    margin-bottom: 8px;
}

.links {
    margin-top: 12px;
}

.links>* {
    margin-bottom: 8px;
}

.goToStageButton {
    color: #ce871c;
}

.goToLoungeButton {
    color: #963ffa;
}

.attendeeDivider {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.talkButton {
    border-color: var(--primary);
    color: var(--primary);
    position: absolute;
    right: -6px;
    border: none;
}

.busy {
    height: 24px;
    padding: 0px 7px;
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid #ce871c;
    color: #ce871c;
    margin-left: auto;
}

.notification {
    background: var(--red);
    width: 4px;
    height: 4px;
    margin-left: 5px;
    border-radius: 50%;
}

.tabWithNotification {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    color: #9a9a9d;
    font-weight: 600;
    font-size: 14px;
    padding: 16px 12px;
    border-bottom: 1px solid #f0f0f0;

}


.onlineUsers {
    margin-top: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-left: 4px;
    color: #cbcbcb;
}

.onlineBubble {
    background: rgb(1, 222, 119);
    width: 4px;
    height: 4px;
    margin-left: 5px;
    border-radius: 50%;
    margin-right: 2px;
}

.actions {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}
