.container {
    /* margin-top: 12px; */
    max-width: 900px;
}

.content {
    margin-top: 50px;
}

.team {
    margin-top: 80px;
}

.strip {
    margin-top: 38px;
    display: flex;
    flex-direction: row;
}

@media (max-width: 599px) {
    .strip {
        display: flex;
        flex-direction: column;
    }
}

.strip > * {
    margin-right: 16px;
    margin-bottom: 16px;
    flex-shrink: 0;
    width: 220px;
}


@media (max-width: 599px) {
    .strip > * {
        width: 100%;
    }
}

.title {
    /* justify-content: center; */
    display: flex;
    align-items: center;
}

.logo {
    height: 40px;
    margin-top: -15px;
    margin-right: 10px;
    margin-left: -12px;
}

@media (max-width: 599px) {
    .title {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .logo {
        height: 30px;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 12px;
    }
}

.contactLink {
    font-weight: 600;
}