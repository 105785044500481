.modalHeader {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 12px;
}

.setTimerButton {
    margin-top: 12px;
}

.cancelTimerButton {
    margin-left: 8px;
}

.clearTimerButton {
    margin-top: 12px;
    border-color: #ce871c;
    color: #ce871c;
}

.enblePrivateTalksContainer {
    margin-top: 20px;
}


.externalLinksContainer {
    margin-top: 20px;
}

.header {
    font-weight: 700;
    color: #00063c;
    margin-bottom: 2px;
}

.links {
    margin-top: 12px;
}

.links > * {
    margin-bottom: 8px;
}