.image {
    width: 100px;
    object-fit: cover;
    height: 100px;
}

.uploadText {
    font-size: 14px;
    color: var(--text-secondary-color);
}

.removeButton {
    margin-top: 0px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    color: var(--material-color-red);
}