

.container {
    color: var(--text-secondary-color);
}

.generateButton {
    margin-top: 20px;
}

.yourLinksContainer {
    margin-top: 50px;
}

.link {
    font-weight: 600;
}

.referrals {
    margin-top: 50px;
}

.linkModal {

}

.linkModalContent {

}

.subHeader {
    font-weight: 700;
    margin-bottom: 12px;
    font-size: 16px;
}

.option{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}