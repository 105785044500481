.container {
    position: relative;
    max-width: var(--max-width-default);
    padding-bottom: 30px;
}

.detailsContainer {
    display: flex;
}

@media (max-width: 599px) {
    .detailsContainer {
        flex-direction: column;
    }
}

.mainDetails {
    position: relative;
    width: 60%;
    margin-right: 16px;
    padding: 30px;
    overflow: hidden;
}

@media (max-width: 599px) {
    .mainDetails {
        width: 100%;
        margin-right: 0px;
    }
}

.extraDetails {
    display: flex;
    flex-direction: column;
    width: 40%;
    position: relative;
    position: sticky;
    top: calc(var(--header-size) + 0px);
    display: flex;
    width: 100%;
    height: auto;
    margin-top: 0;
    flex-direction: column;
    align-self: flex-start;
    flex: 1;
}

.sticky {
    position: fixed;
    top: 60px;
    width: 100%;
}

@media (max-width: 599px) {
    .extraDetails {
        margin-top: 16px;
        width: 100%;
        margin-right: 0px;
    }
}

.regsiterContainer {
    height: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 16px;
    flex-shrink: 0;
}

.registerSnippet {
    font-weight: 700;
    margin-bottom: 16px;
}

.eventTitle {
    font-size: 22px;
    line-height: 1.6;
    font-weight: bold;
    width: 80%;
}

.liveBadge {
    margin-left: 8px;
}

.divider {
    height: 1px;
    width: 100%;
    background-color: #e9e9e9;
    margin-top: 20px;
    margin-bottom: 20px;
}

.dateContainer {
    display: inline-flex;
}

.date {
    display: flex;
    align-items: center;
    color: #727C8B;
    font-weight: 600;
    font-size: 15px;
}

@media (max-width: 599px) {
    .dateContainer {
        display: flex;
        flex-direction: column;
    }
    .date {
        line-height: 1.4;
    }
    .addToCalendar {
        display: flex;
        margin-top: 8px;
    }
}

.icon {
    font-size: 14px;
    color: #727C8B;
}

.location {
    composes: date;
    margin-top: 14px;
}

.miniHeader {
    margin-top: 44px;
    font-size: 18px;
    font-weight: bold;
}

.speakersGrid {
    margin-top: 24px;
    display: grid;
    row-gap: 16px;
    column-gap: 16px;
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 599px) {
    .speakersGrid {
        grid-template-columns: 1fr;
    }
}

.dateAndLocationContainer {
    margin-top: 16px;
}

.date>div {
    margin-left: 12px;
}

.eventDescription {
    margin-top: 44px;
    line-height: 1.6;
}

.adminSection {
    height: fit-content;
    margin-top: 16px;
}

.adminOptionsButton {
    font-size: 12px;
    opacity: 0.8;
    color: var(--text-secondary-color);
    margin-top: 12px;
    cursor: pointer;
}

.adminSnippet {
    font-size: 12px;
    color: var(--text-secondary-color);
    margin-top: 12px;
}

.registerButton {
    width: 100%;
    font-weight: 700;
    height: 50px;
}

.startEventButton {
    composes: registerButton;
    margin-bottom: 8px;
}

.endEventButton {
    composes: registerButton;
    margin-bottom: 8px;
    color: #ff7010;
    background-color: white;
    border-color: #ff7010;
}

.deleteEventButton {
    margin-top: 8px;
    composes: registerButton;
    border-color: red;
    color: red;
}

.attendeesContainer {
    margin-top: 50px;
}

@media (max-width: 599px) {
    .attendeesContainer {
        padding-bottom: 48px;
    }
}

.membersContainer {
    margin-top: 28px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.membersContainer>* {
    margin-right: 16px;
    margin-bottom: 16px;
}

@media (max-width: 599px) {
    .membersContainer>* {
        margin-right: 0px;
    }
}

.beFirstToJoinText {
    text-align: center;
    margin-top: 20px;
    color: var(--text-secondary-color);
}

.attendeesSnippet {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.attendessSnipetText {
    text-align: center;
    margin-top: 14px;
    font-size: 14px;
    color: var(--text-secondary-color);
}

.notificationIcon {
    color: var(--primary);
}

.navBarMobile {
    display: none;
}

@media (max-width: 599px) {
    .navBarMobile {
        justify-content: center;
        align-items: center;
        padding: 12px;
        position: fixed;
        display: flex;
        width: 100%;
        bottom: 0px;
        left: 0px;
        box-shadow: 0px -4px 10px rgba(211, 211, 211, 0.5);
        background-color: white;
    }
}

.themeStrip {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 6px;
    background-color: var(--primary);
}

.themesContainer {
    margin-top: 10px;
}

.applicationButtons {
    margin-top: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4px;
}

.approveButton {
    border-color: var(--ok-green);
    color: var(--ok-green);
}

.denyButton {
    border-color: var(--red);
    color: var(--red);
}

@media (max-width: 599px) {
    .applicationContainer {
        width: 100%;
    }
}