.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .container {
        flex-direction: column;
        text-align: center;
    }
}

.messageContainer {
    margin-left: 20px;
}

@media (max-width: 599px) {
    .messageContainer {
        margin-left: 0px;
        margin-top: 16px;
        margin-bottom: 20px;
    }
}

.title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 2px;
}

.subTitle {
    color: var(--text-secondary-color);
}

.buttonsContainer {
    margin-left: 24px;
}

@media (max-width: 599px) {
    .buttonsContainer {
        margin-top: 16px;
    }
}

.acceptButton {
 width: 100%;
}


.rejectButton {
    margin-top: 10px;
    width: 100%;
    border-color: var(--red);
    color: var(--red);
}