.container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 100%;
    line-height: 1.5;
    text-align: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    overflow-y: auto;
    background-color: var(--background-color);
}

.loaderContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header {
    display: flex;
    padding: 12px;
    position: relative;
    width: 100%;
    position: fixed;
    top: 0px;
}

.logo {
    margin-left: 12px;
    width: 120px;
    position: absolute;
    right: 12px;
}

.homeButton {
    position: absolute;
    left: 12px;
    ;
}

.completionModalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
}

.completionHeader {
    font-size: 22px;
    font-weight: 700;
}

.completionDescription {
    margin-top: 12px;
    font-size: 16px;
    color: var(--text-secondary-color);
}

.completionButtonsContainer {
    margin-top: 34px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
}

.completionButtonsContainer>* {
    margin-bottom: 8px;
}

.keepPosted {
    composes: completionDescription;
    margin-top: 18px;
}

.doneAnimation {
    margin-top: -20px;
    width: 150px;
    height: 120px;
}

.dateAndTime {
    margin-top: 22px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.8;
    color: var(--text-secondary-color);
}

.dateAndTimeIcon {
    margin-right: 4px;
    /* color: var(--primary); */
}