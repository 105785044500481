.container {
    height: 100%;
    color: var(--text-color);
    position: relative;
}

.sider {
    box-shadow: 0 2px 10px 0 rgba(151, 151, 151, 0.5);
    background-color: #ffffff;
    z-index: 2;
}

@media(max-width: 599px) {
    .sider {
        position: fixed;
        height: 100vh;
        left: 0px;
    }
}

.siderHeader {
    display: flex;
    padding: 12px 20px;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background-color: var(--primary);
}

.siderLogo {
    height: 18px;
    cursor: pointer;
    /* filter: brightness(0) invert(1); */
}

.menuIcon {
    opacity: 0.6;
    width: 24px;
    cursor: pointer;
}

.siderMenu {
    margin-top: 20px;
}

.content {
    position: relative;
    flex: auto;
    min-height: auto;
    height: auto;
    padding: 0px 12px 0px 75px;
    margin-top: 64px;
    background-color: var(--background-color);
}

.fullPageMode {
    padding: 0px !important;
    margin-top: 0px;
}

@media (max-width: 599px) {
    .content {
        padding: 12px 12px;
    }
}

.header {
    background-color: var(--background-color);
    position: fixed;
    top: 0;
    height: var(--header-size);
    right: 0;
    padding: 0 20px;
    z-index: 1;
}

@media (max-width: 599px) {
    .header {
        padding: 0 12px;
    }
}

.menuItem {
    display: flex;
    align-items: center;
    position: relative;
}

.menuWithBadge {
    display: flex;
    align-items: center;
}

.menuBadge {
    position: absolute;
    padding: 3px 5px;
    height: 20px;
    right: 10px;
    background-color: #57c676;
    color: white;
    font-weight: 600;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    text-transform: uppercase;
}

.siderBottom {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 30px;
    right: 0;
    left: 0;
}

.logo {
    width: 130px !important;
    margin-left: -5px;
}