.modal {
    width: 750px !important;
    /* overflow: hidden; */
}

.container {
    height: 450px;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
}

.founderAvatar {
    position: absolute;
    top: -40px;
}

.avatar {
    width: 80px;
    height: 80px;
    border: 2px #1cbcbe solid;
    box-shadow: rgba(21, 21, 25, 0.208) 0px 10px 20px;
}

.content {
    margin-top: 50px;
}

.header {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 12px;
}

.textsContainer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.text {
    font-size: 15px;
    line-height: 1.7;
    color: #29232d;
    max-width: 600px;
}

@media (max-width: 599px) {
    .text {
        padding: 0px 8px;
    }
}

.phase {
    display: flex;
    flex-direction: column;
}

@media (max-width: 599px) {
    .phase {
        /* flex-direction: row; */
    }
}

.bolded {
    font-weight: 700;
}

.progressBar {
    position: absolute;
    top: -10px;
    z-index: 10;
}

.progressBarRaw {
    height: 6px;
    width: 0px;
    position: absolute;
    bottom: -0px;
    background-color: var(--primary);
    animation: progress 42s linear forwards;
    z-index: 10;
}

@keyframes progress {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

.thirdText {
    /* max-width: 500px; */
}

.forthText {
    /* max-width: 400px; */
}

.exploreButton {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 200px;
}

.teamText {
    font-size: 14px;
    color: var(--text-secondary-color);
    margin-bottom: 12px;
}

.teamAvatar {
    width: 40px;
    height: 40px;
}

:global .welcome-modal .ant-progress-inner {
    border-radius: 0px;
    height: 5px;
}

:global .welcome-modal .ant-progress-bg {
    border-radius: 0px;
}

/* scenes */

.sceneBase {
    position: absolute;
    height: 240px;
    right: 0;
    left: 0;
    bottom: 0px;
    overflow: hidden;
}

.firstScene {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;
}

.firstScene .right {
    width: 180px;
    position: absolute;
    bottom: -35px;
    right: -20px;
}

.firstScene .left {
    width: 180px;
    position: absolute;
    bottom: -17px;
    left: -20px;
}

.logoAnimation {
    margin-top: -80px;
    width: 320px;
}

.secondScene {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;
}

.secondSceneContainer {
    margin-top: -80px;;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

@media (max-width: 599px) {
    .secondSceneContainer {
        margin-top: -40px;;

    }
}


.tableScene {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;
}

.tableVideoContainer {
    margin-top: -70px;
    margin-left: -30px;
    height: 200px;
    object-fit: contain;
}

@media (max-width: 599px) {
    .tableVideoContainer {
        margin-top: -20px;
    }
}

.scAvatar {
    transition: all 300ms;
}

.emojiGif {
    position: absolute;
    top: -0px;
    width: 100px;
}

.eventsScene {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
}

.eventsSceneLogosGrid {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 480px;
}

@media (max-width: 599px) {
    .eventsSceneLogosGrid {
        margin-top: 60px;

    }
}


.eventsSceneLogo {
    margin-right: 12px;
    margin-bottom: 12px;
    width: 70px;
    height: 70px;
}

@media (max-width: 599px) {
    .eventsSceneLogo {
        width: 40px;
        height: 40px;
    }
}


.welcomeText {
    max-width: 700px;
}