.container {
    padding: 40px 40px 30px 40px;
    margin: 0 8px;
    width: 100%;
    max-width: 500px;
    background-color: black;
    background-image: url('./invite-bg.jpg');
    animation: movingBackground 8s ease infinite;
    color: white;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    text-align: center;
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);

}

@media (max-width: 599px) {
    .container {
        padding: 30px 8px;
        text-align: center;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.subHeader {
    font-size: 13px;
    opacity: 0.8;
}

.header {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.3;
}

.date {
    margin-top: 4px;
    font-size: 14px;
    opacity: 1;
}

.role {
    margin-top: 20px;
    background-color: #ffc300;
    color: #000000c2;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 6px;
    font-weight: 600;
    border-radius: 5px;
}

.starIcon {
    margin-right: 3px;
    transform: scale(0.8);
}

.inviterText {
    color: #181d26;
    font-size: 14px;
    font-weight: 600px;
    margin-left: 8px
}

.inviterContainer {
    margin-top: -10px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.inviterContainer > * {
    margin-right: 10px;
}

.avatar {
    border: 1px solid white;
}

.boardingPassIcon {
    position: absolute;
    width: 200px;
    right: -80px;
    bottom: 0px;
    transform: rotateY(180deg);
    z-index: 1;
}

@media (max-width: 599px) {
    .boardingPassIcon {
        width: 120px;
        right: -40px;
    }
}

@keyframes movingBackground {
	0% {
		background-position: 00% 00%;
	}
	50% {
		background-position: 60% 60%;
	}
	100% {
		background-position: 00% 00%;
	}
}

.closeIcon {
    color: white;
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    opacity: 0.8;
}