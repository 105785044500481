.content {
    color: var(--text-secondary-color);
    font-size: 16px;
    padding: 30px 24px;
    display: grid;
    grid-gap: 60px;
}

@media(max-width: 599px) {
    .content {
        padding: 30px 12px;
    }
}

.speakersContainer {
    margin-top: 20px;
}

.speakersLabel {
    font-size: 14px;
    color: #a4a4a4;
    font-weight: 700;
}

.uploadText {
    font-size: 14px;
    color: var(--text-secondary-color);
}

:global .add-event .ant-select-selection-item {
    padding-top: 10px !important;
}

:global .add-event .ant-select-selection-placeholder {
    padding-top: 10px !important;
}

:global .add-event textarea {
    min-height: 140px;
}

.themeColor {
    position: relative;
}

.colorPicker {
    position: absolute;
    right: 12px;
    top: 45px;
    width: 30px;
    height: 30px;
    border: 1px #9b9b9b solid;
    margin-left: 8px;
}

.smallInput {
    width: 120px;
}
.smallInput2 {
    width: 60px;
}

.side2side {
    display: flex;
}

.side2side > * {
    margin-right: 12px;
}

.row {
     display: flex;
     flex-direction: row;
     align-items: center;
}

.suffix {
    margin-left: 12px;
    font-size: 12px;
    color: var(--text-secondary-color);
}

