.container {
    width: 100%;
    max-width: 400px;
    background-color: #f6f7fa;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    padding: 30px;
}


.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 18px;
    z-index: 99;
}

@media (max-width: 599px) {
    .textContainer {
        margin-left: 10px;
        margin-top: 12px;
    }
}

.header {
    color: black;
    font-size: 20px;
    font-weight: 700;
}

.secondaryText {
    color: var(--text-secondary-color);
    margin-top: 2px;
    font-size: 14px;
    font-weight: 400px;
}

.boardingPassIcon {
    position: absolute;
    width: 200px;
    right: -80px;
    bottom: 0px;
    transform: rotateY(180deg);
    z-index: 1;
}

@media (max-width: 599px) {
    .boardingPassIcon {
        width: 120px;
        right: -40px;
    }
}

.boardingPassHeader {
    width: 100%;
    position: absolute;
    top: 0;
    background-color: var(--primary);
    display: flex;
    align-items: center;  
    color: white;
    font-weight: 600;
    padding: 8px 20px;
}

.dashed {
    position: absolute;
    right: 0;
    border-left: 1px dashed #dddcd9;
    width: 90px;
    height: 100%;
}

.boardingPassText {
    text-transform: uppercase;
    opacity: 0.9;
    font-size: 10px;;
}

.mockingContainer {
    margin-top: 35px;
    padding: 8px 12px;
}

.mockingHeader {
    font-weight: 800;
    text-transform: uppercase;
    color: var(--dark-black);
    margin-bottom: 4px;
    opacity: 0.8;
    font-size: 10px;
}

.mockingText {
    height: 8px;
    background-color: #bcbdc1;
    border-bottom: 8px;
    width: 40px;
}

.barcode {
    margin-top: 20px;
    height: 10px;
    width: 100%;
    opacity: 0.7;
}