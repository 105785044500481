.container {
    display: flex;
    width: fit-content;
    padding: 5px 8px 5px 8px;
    font-weight: 600;
    border-radius: 2px;
    font-size: 12px;
    color: white;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.653);
}


.emoji {
    width: 14px;
    margin-right: 2px;
    margin-left: -2px;
    animation: bounce 0.9s infinite ease-in-out alternate-reverse;
}

@keyframes bounce {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
}