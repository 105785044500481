.buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 12px;
    position: fixed;
    display: flex;
    width: 100%;
    bottom: 0px;
    left: 0px;
    box-shadow: 0px -4px 10px rgba(211, 211, 211, 0.5);
    background-color: white;

}

@media(max-width: 599px) {
    .buttonsContainer {
        margin-top: 45px;
    }
}