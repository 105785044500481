.container {
    width: 100%;
    max-width: 700px;
    line-height: 1.5;
    padding: 28px;
}

.header {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 8px;
    color: var(--dark-black);
}

@media (max-width: 599px) {
    .header {
        font-size: 16px;
    }
}

.description {
    font-size: 15px;
    margin-bottom: 16px;
    color: var(--dark-black);
}

.creditsNumber {
    color: var(--primary);
}

.premiumMember {
    font-weight: 700;
    margin-bottom: 12px;
    /* color: var(--gold); */

}

.buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .buttonContainer {
        display: grid;
        row-gap: 12px;
    }
}


.ctaButton {
    font-size: 16px;
    font-weight: 700;
}

.discountBadge {
    margin-left: 12px;
}

@media (max-width: 599px) {
    .discountBadge {
        margin-left: 0px;
    }
}


.socialProofContainer {
    margin-top: 12px;
}

.socialProofText {
    font-size: 13px;
    color: var(--text-secondary-color);
}

.companies {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
}

.companies img {
    width: 56px;
    margin-right: 10px; 
}