.container {
    width: 100vw !important;
    max-width: 800px;
    overflow: hidden;
}

.content {
    padding-top: 20px;
    /* width: 900px; */
    min-height: auto;
}

.choosingContainer {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
}

@media (max-width: 599px) {
    .choosingContainer {
        flex-direction: column;
    }
}

.choosingBoxContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1 1;
}

.memberChoosingBoxContainer {
    border: 1px var(--kinky-grey) solid;
    padding: 30px 20px;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    cursor: pointer;
}

.selectBox {
    border: 1px var(--easy-grey) dashed !important;
    transition: all 0.3s;
}

.selectBox:hover {
    border-color: var(--primary) !important;
}

.header {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
}

.arrow {
    width: 50px;
}

@media (max-width: 599px) {
    .arrow {
        transform: rotate(90deg);
    }
}

.texts {
    margin-left: 24px;
}

.choosingBoxHeader {
    font-weight: 500;
    font-size: 16px;
    /* text-decoration: underline;
    text-decoration-color: #b5b5b5;
    text-decoration-style: dotted; */
    color: var(--text-secondary-color);
    cursor: pointer;
}

.choosingBoxSubHeader {
    color: var(--text-secondary-color);
    margin-top: 0px;
    font-size: 14px;
}

.flexRow {
    display: flex;
    align-items: center;
}

.arrow {
    font-size: 20px;
    margin-top: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.choosingBoxMemberName {
    font-size: 16px !important;
    font-weight: 700;
}

.info {
    margin-top: 18px;
}

.introducer {
    margin-top: 30px;
}

.introducerButton {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 12px 20px;
    font-size: 15px;
    width: fit-content;
    border: 1px solid var(--easy-grey);
    user-select: none;
}

.menuButton {
    display: flex;
    user-select: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    font-size: 15px;
    width: fit-content;
}

.introducerName {
    margin-left: 12px;
    font-weight: 600;
}

.youBadge {
    margin-left: 12px;
    padding: 0px 4px;
    font-size: 9px;
    background-color: var(--ok-green);
    border-radius: 4px;
    color: white;
}

.downIcon {
    margin-left: 8px;
}

.description {
    margin-top: -12px;
    font-size: 15px;
    color: var(--text-secondary-color);
}

.choosingBoxLocation {
    margin-top: 2px;
    font-size: 12px;
    font-weight: 600;
    color: var(--text-secondary-color);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.location {
    display: flex;
    align-items: center;
}

.alert {
    border-color: var(--primary);
    background-color: var(--primary-contrast-light);
}

.notesInput {
    margin-top: 18px;
    font-weight: 600 !important;
}

.previewButton {
    /* position: absolute; */
    /* border-color: #8800ff;
    color: #8800ff; */
    /* background-color: #8800ff15; */
}


.previewHeader {
    margin-bottom: 4px;
    font-weight: 700;
    color: black;
    font-size: 16px;
}

.subject {
    font-size: 16px;
    color: black;
    font-weight: 600;
}

.emailContent {
    margin-top: 10px;
    color: var(--dark-purple);   
}