.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.container:hover .handler {
    opacity: 1;
}

.topHeader {
    /* position: fixed;
    top: 0px; */
    height: auto;
    z-index: 10;
    width: 100%;
    background: white;
    display: flex;
    padding: 12px;
    align-items: center;
}

.headerTitle {
    font-weight: 600;
    font-size: 14px;
    color: var(--dark-purple);
}

.smallLogoButton {
    margin-left: auto;
}


.participantsInfo {
    overflow: auto;
    /* margin-top: 30px; */
    padding: 10px 24px 24px 24px;
    /* padding-right: 32px; */
}

.userBasicInfo {
    display: flex;
    align-items: center;
}

.userBasicInfoTexts {
    margin-left: 12px;
}

.userName {
    font-size: 18px;
    font-weight: bold;
}

.userAvatar {
    /* transform: scale(1.2); */
}

.title {
    margin-top: 8px;
    font-size: 15px;
    line-height: 1.6;
    color: var(--text-secondary-color);
}

.bio {
    margin-top: 30px;
    line-height: 1.7;
    font-size: 15px;
    color: var(--dark-black);
}

.company {
    margin-top: 50px;
    line-height: 1.6;
}

.interests {
    margin-top: 30px;

}

.location {
    margin-top: 30px;
}

.industryTags {
    display: flex;
    flex-wrap: wrap;
}

.industryTags>* {
    margin-right: 6px;
    margin-bottom: 6px;
}

.subHeader {
    font-weight: bold;
    color: var(--dark-black);
    font-size: 14px;
    margin-bottom: 16px;
}

.linkedCompany{
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: #b5b5b5;
    text-decoration-style: dotted;
    cursor: pointer;
}


.handler {
    height: 80px;
    width: 20px;
    top: 45%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    left: -24px;
    display: flex;
    opacity: 0;
    justify-content: center;
    align-items: center;
    color: #a2a2a2;
    transition: all 0.3s;
    cursor: pointer;
    border: 1px solid #a2a2a2;
}

.handler:hover {
    background-color: rgba(255, 255, 255, 1);
    opacity: 1;
}


@media (max-width: 599px) {
    .handler {
        opacity: 1;
        color: var(--dark-purple);
        background-color: rgba(255, 255, 255, 0.9);
        border: 1px solid rgb(216, 216, 216);
        box-shadow: rgba(21, 21, 25, 0.708) 0px 10px 40px;
    }
    .handler:hover {
        background-color: rgba(255, 255, 255, 0.756);
    }
}

.handlerTablet {
    composes: handler;
    opacity: 1;
}

