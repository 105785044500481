.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 24px 0px 24px;
    font-size: 16px;
}

@media (max-width: 599px) {
    .container {
        padding: 40px 12px 20px 12px;
    }
}

.header {
    font-weight: 800;
    font-size: 24px;
    line-height: 1.4;
    text-align: center;
}

.subHeader {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    color: var(--text-secondary-color);
} 

.upcomingEventsContainer {
    width: 100%;
    margin: 0px 0px 30px 0px;
}

.upcomingEventsHeader {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    margin-bottom: 12px;
}

.events {
    display: grid;
    grid-template-rows: 1fr;
    row-gap: 8px;
    width: 100%;
    padding: 0px 20px;
}

@media (max-width: 599px) {
    .events {
        padding: 0px;
    }
}

.eventItem {
    position: relative;
    padding: 12px;
    width: 100%;
    border: 1px var(--kinky-grey) solid;
}


.eventTitle {
    font-weight: 800;
    margin-bottom: 2px;
    font-size: 16px;
    color: var(--dark-black);
}

.eventDate {
    color: var(--text-secondary-color);
    font-size: 14px;
    margin-bottom: 16px;
    color: #008e8e;
    font-weight: 700;
}

.peopleGoing {
    line-height: 1;
    align-items: flex-start;    
}

.attendToButton {
    position: absolute;
    bottom: 12px;
    right: 12px;
    font-weight: 600;
}

@media (max-width: 599px) {
    .attendToButton {
        margin-top: 18px;
        position: static;
    }
}

.currentMemberSnippet {
    display: flex;
    font-size: 14px;
    color: var(--text-secondary-color);
}

.currentMemberSnippet > *{
    margin-right: 8px;
}

.followContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 18px;
    border-radius: 12px 12px 0px 0px;
    background-color: var(--primary-contrast-light);
}

.notifyText {
    margin-right: 8px;
    margin-bottom: 12px;
    color: black;
}

.bubblesContainer {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
}

.userAvatar {
    margin-right: -8px;
}