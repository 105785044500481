.container {
    width: 100%;
    height: 100vh;
    padding: 40px 30px 00px 30px;
    background: url('./../stage/bg.jpg') no-repeat;
    background-size: cover;
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

.topStrip {
    height: 50px;
    color: white;
    flex-shrink: 0;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .topStrip {
        flex-direction: column;
        height: auto;
        margin-bottom: 20px;
        align-items: flex-start;
    }
}

.buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.adminButtons {
    display: flex;
    align-items: center; 
}

@media (max-width: 599px) {
    .buttons {
        margin-top: 16px;
        margin-left: 0px;
    }
}

.header {
    font-size: 24px;
    font-weight: 700;
}

.subHeader {
    margin-top: 12px;
    font-size: 15px;
    opacity: 0.9;
}

.content {
    margin-top: 20px;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.content::-webkit-scrollbar {
    display: none;
  }

.roundTables {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 16px;
    row-gap: 16px;
    padding-bottom: 30px;
}

@media (max-width: 1600px) {
    .roundTables {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 1400px) {
    .roundTables {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 599px) {
    .roundTables {
        grid-template-columns: 1fr;
    }
}

.settingsButton {
    width: 40px;
    height: 40px;
    border: 1px white solid;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    margin-left: 12px;
    cursor: pointer;
}

