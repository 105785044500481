.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 5px 20px;
}

@media (max-width: 599px) {
    .container {
        padding: 0px;
    }
}

.header {
    font-weight: 800;
    font-size: 28px;
    line-height: 1.4;
    text-align: center;
}

.subHeader {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
} 

.textArea {
    margin-top: 16px;
    width: 100%;
}


.loginButton {
    font-weight: 600;
    width: 100%;
}


:global .question-form textarea {
    min-height: 100px;
}

.askingAsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 12px;
    color: var(--text-secondary-color);
}

.askingAsContainer div {
    margin-left: 8px;
}
.askingAsContainer span {
    font-weight: 600;
}