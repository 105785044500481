.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 12px;
    padding-left: 12px;
    line-height: 1.5;
    height: 100%;
}

@media (max-width: 599px) {
    .container {
        height: auto;
        padding: 20px 0px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 680px;
    height: 100%;
    width: 100%;
}

@media (max-width: 599px) {
    .content {
        justify-content: flex-start;
        margin-top: 20px;
    }
}

@media (max-height: 720px) {
    .content {
        justify-content: flex-start;
        padding: 20px 0px;
    }
}


.invitation {
    margin-top: 28px;
    width: 100%;
    justify-content: center;
    display: flex;
}

.textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    line-height: 1.7;
    justify-content: center;
}

.header {
    font-size: 20px;
    font-weight: 800;
}

.subHeader {
    margin-top: 4px;
    font-size: 15px;
    text-align: center;
    color: #636363;
    max-width: 500px;
    margin: 0px 12px;    
}

.loginButtons {
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 40px;
    width: 100%;
    height: auto;
    max-width: 400px;
}

.tosContainer {
    margin-top: 24px;
    font-size: 12px;
    color: #636363;
    line-height: 1.5;
    text-align: center;
}

.credentialContainer {
    width: 100%;
}

.divider {
    font-size: 14px !important;
    color: var(--text-secondary-color) !important;
}

.loginButton {
    font-weight: 600;
    width: 100%;
}

.forgotPasswordContaienr {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 50px 0px 30px 0px;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.signUpWithCredentials {
    font-size: 12px;
    margin-top: 12px;
}

.signupContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.alreadyMemberContainer {
    cursor: pointer;
    user-select: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0px 10px 0px;
    color: #b7b7b7;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.alreadyMemberContainer>* {
    margin-bottom: 12px;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.modalHeader {
    font-weight: 800;
    font-size: 28px;
    line-height: 1.4;
    text-align: center;
}

.modalSubHeader {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.emailAnimation {
    width: 160px;
}

.travelTechNation {
    position: absolute;
    padding: 16px;
    border: 1px solid #f2f2f2;
    bottom: 0px;
    left: 0px;
    font-size: 15px;
    font-weight: 600;
    color: var(--text-secondary-color);
    /* display: flex; */
    justify-content: center;
    cursor: pointer;
}

@media (max-width: 599px) {
    .travelTechNation {
        position: static;
        margin-bottom: -20px;
        margin-top: 50px;
        text-align: center;
    }
}

.ttnLogo {
    height: 18px;
    margin-left: 2px;
    margin-top: -9px;
}
.pwLogo {
    height: 20px;
    margin-top: -4px;
    margin-left: 3px;
}

.loaderContainer{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}