.container {
    height: auto;
    height: calc(100vh - var(--header-size));
    overflow: scroll;
    /* margin-bottom: -30px; */
    position: relative;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.container::-webkit-scrollbar {
    display: none;
}

.membersGrid {
    display: flex;
    flex-wrap: wrap;
    max-width: var(--max-width-default);
    padding: 20px 0px 0px 0px;
}

.membersGrid>* {
    margin-right: 16px;
    margin-bottom: 16px;
}

@media(max-width: 599px) {
    .membersGrid>* {
        margin-right: 0px;
        margin-bottom: 16px;
    }
}

.loader {
    width: 40px;
    height: 40px;
}

.showMoreButton {
    display: none;
}

@media (min-height: 1000px) {
    .showMoreButton {
        display: initial;
    }
}

.sectionContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: var(--max-width-default);
}

.suggestMemberButton {
    bottom: 0;
    position: absolute;
    right: 34px;
    font-weight: 700;
}

@media (max-width: 599px) {
    .sectionContainer {
        display: flex;
        flex-direction: column-reverse;
    }
    .suggestMemberButton {
        margin-top: 10px;
        position: static;
    }
}


.suggestIcon {
    width: 22px;
    margin-right: 4px;
}