.membersGoginContainer {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    color: #a9acb0;
    flex-shrink: 0;
}

.teamAvatar {
    width: 20px;
    height: 20px;
}

.membersGogingText {
    font-size: 11px;
    margin-top: 4px;
}

.icon {
    margin-right: 1px;
    width: 12px;
    height: 12px;
}