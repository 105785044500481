

.container {
    color: var(--text-secondary-color);
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.name {
    font-weight: 600;
}

.users {
    margin-top: 20px;
}

.badges {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.badges > * {
    margin-left: 8px;
}

.badge {
    padding: 2px 6px;
    font-size: 12px;
    background-color: var(--ok-green);
    border-radius: 4px;
    font-weight: 600;
    color: white;
}

.leadersBadge {
    composes: badge;
    background-color: var(--primary);
    color: white;
}

.guestBadge {
    composes: badge;
    background-color: #009d66;
    color: white;
}

.managerBadge {
    composes: badge;
    background-color: #1103a9;
    color: white;
}


.staffBadge {
    composes: badge;
    background-color: #d00061;
    color: white;
}

.pendingBadge {
    composes: badge;
    background-color: #9d7d00;
    color: white;
}

.filter {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.usersCount {
    font-size: 14px;
    color: var(--text-secondary-color);
    margin-left: 12px;
}

.exportButton {
    margin-left: auto;
}