:root {
    --videoContainerPaddingSize: 2px;
    --videoContainerBorderRadius: 10px;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 1;
    
}

.videoContainer {
    position: relative;
    border-radius: var(--videoContainerBorderRadius);
    overflow: hidden; 
    padding: var(--videoContainerPaddingSize);
    height: 100%;
}

.video {
    border-radius: var(--videoContainerBorderRadius);   
    width: 100%;
    height: 100%;
    object-fit: cover;
    flex-shrink: 1;
}


.local {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #cc23d5, #d54c23);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
    /* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab); */

}

.other {
    background: linear-gradient(-45deg, #1CBEC0, #3cb9e7, #235bd5, #09dcab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


.videoOverlay {
    position: absolute;
    bottom: var(--videoContainerPaddingSize);
    right: var(--videoContainerPaddingSize);
    left: var(--videoContainerPaddingSize);
    height: 100px;    
    border-bottom-right-radius: var(--videoContainerBorderRadius);
    border-bottom-left-radius: var(--videoContainerBorderRadius);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}

.userInfo {
    position: absolute;
    bottom: 0;
    padding: 20px 20px 22px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.texts {
    margin-left: 20px;
}

.participantName {
    font-weight: 600;
    font-size: 16px;
    color: white;
}

.participantTitle {
    font-size: 14px;
    color: white;
    margin-top: 8px;
}

.controlButtons {
    display: flex;
    flex-direction: row;
    justify-self: flex-end;
    align-self: flex-end;
    margin-left: auto;

}

.controlButtons > * {
    margin-left: 16px;
}


.message {
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    
}

.messageContainer {
    display: flex;
    padding: 0px 4px 4px 4px;
    font-size: 16px;
    flex-direction: column;
}

.cameraOffContainer {
    display: flex;
    height: 100%;
    width: 100%;
    min-height: 300px;
    justify-content: center;
    align-items: center;
}

.avatar {
}

.controlButton {
    padding: 5px;
}