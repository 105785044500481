.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    text-align: center;
}

.header {
    margin-top: 38px;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
}

.logout {
    margin-top: 50px;
    font-size: 14px;
}

.button {
    margin-top: 30px;
    padding: 0px 40px;
    font-weight: 600;
}