.container {
    width: 100%;
    height: 100%;
    max-height: 250px;
    background: url(./invite-bg.jpg) no-repeat;
    animation: movingBackground 8s ease infinite;
    background-size: cover;
    color: white;
    padding: 0;
    text-align: center;
    line-height: 1.4;
    position: relative;
    overflow: hidden;
}

.overlay {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1 );
    padding: 38px 22px;
}

.header {
    font-size: 18px;
    font-weight: 700;
}

.subHeader {
    margin-top: 2px;
    font-size: 15px;
}

.countdownWrapper {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
}

.countdownWrapper > * {
    margin-right: 10px;
}

.countdownItem {
	background-color: var(--primary);
	font-size: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 44px;
	height: 44px;
    font-weight: bolder;
    border-radius: 5px;
}

.countdownTimeDescription {
    margin-top: 3px;
    font-size: 12px;
}

.countdownItem span {
	font-size: 10px;
	font-weight: 400;
	text-transform: uppercase;
}

.activatingTitle {
	margin-left: 8px;
	margin-right: 8px;
	font-weight: 500;
}

@media (max-width: 599px) {
	.countdownItem { 
		width: 45px;
		height: 45px;

	}
}

.joinButton {
    font-weight: 700;
    width: auto;
    margin-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    background-color: var(--ok-green);
    border: 1px solid rgba(255, 255, 255, 0.954);
}


.recordingButton {
    font-weight: 700;
    width: auto;
    margin-top: 20px;
    border: 1px solid rgba(255, 255, 255, 0.721);
}


.animation {
    position: absolute;
    bottom: -80px;
    width: 100%;
    z-index: 1;
}

@keyframes movingBackground {
	0% {
		background-position: 00% 100%;
	}
	50% {
		background-position: 10% 10%;
	}
	100% {
		background-position: 00% 100%;
	}
}

.addToCalendar {
    margin-top: 24px;   
    font-size: 14px;
    font-weight: 600;
    /* text-decoration: underline; */
    cursor: pointer;
}

.speakerAccess {
    /* position: absolute; */
    right: 12px;
    bottom: 12px;
    margin-top: 12px;
    color: white;
}
.backstageButton {
    background-color: transparent;
    color: white;
    border-color: white;
    font-size: 12px;
}
