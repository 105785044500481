.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.83);
    box-shadow: rgb(21 21 25 / 71%) 0px 10px 40px;
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    width: 270px;
    color: white;
}

.label {
    font-size: 15px;
    line-height: 1.4;
}

.countdown {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 800;
}

.container:hover > .hideButton {
    display: flex;
}

.hideButton {
    background-color: var(--primary);
    font-size: 10px;
    color: white;
    position: absolute;
    top: -8px;
    right: -5px;
    width: 20px;
    height: 20px;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: bold;
    cursor: pointer;
    box-shadow: rgba(21, 21, 25, 0.708) 0px 10px 40px;

}