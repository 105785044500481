
.container {
    display: flex;
    flex-direction: row;
    width:100%;
    height: 100%;
}

.menuIconMobile {
    cursor: pointer;
    width: 26px;
    object-fit: contain;
}

.sidePanel {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    height: 100%;
}


.logo {
    height: 22px !important;
    margin-top: -6px;
    margin-right: 12px;
}
