.container {
    padding: 10px 0px 20px 0px;
    width: 70px;
    border-left: 1px #F7F7F7 solid;
    background-color: white;
    flex-shrink: 0;
    box-shadow: rgba(245, 245, 245, 0.708) 0px 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--dark-purple);
}



.logoContainer {
    width: 43px;
    height: 43px;
    box-shadow: rgba(213, 213, 213, 0.708) 0px 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: all 0.3s;
}

.logo {
    width: 20px;
    flex-shrink: 0;
}

.logoContainer:hover {
    transform: scale(1.1);
}

.bottomItems {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
}

@media (max-width: 599px) {
    .bottomItems {
        margin-bottom: 0px;
    }
    
}

.horizontalDivider {
    margin: 12px 0px;
    background-color: #e1e1e1;
    height: 1px;
    width: 100%;
}

.leaveEventButton {
    margin-top: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

.leaveEventButton img {
    width: 16px;
    margin-bottom: 14px;
}

.leaveEventButton div {
    font-size: 12px;
}


.centerItems{
    margin-top: 50px;
}

.centerItems > * {
    margin-bottom: 34px;
}


.navigationButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
}

.navigationButtonSelected {
    color: var(--primary) !important;
    font-weight: 600 !important;
}

.navigationButtonDisabled {
    color: #c2c2c2;
}

.navigationButton:hover {
    color: var(--primary);
}
.navigationButtonDisabled:hover {
    color: #c2c2c2;
}

.navigationIconWrapper {
    padding: 8px 12px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;

}

.navigationIconWrapperSelected {
    background-color: var(--primary-contrast-light);
}

.navigationIcon {
    font-size: 26px;
    /* margin-top: -4px; */
}

.navigationLabel {
    margin-top: 6px;
    text-align: center;
    font-size: 12px;
}

.statusBubble {
    font-size: 9px;
    font-weight: 600;
    padding: 2px 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    color: white;
    text-transform: capitalize;
    border: 1px white solid;
    position: absolute;
    right: -6px;
    border-radius: 4px;
    top: -8px;
}