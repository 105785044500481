.container {
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    padding: 40px 30px 30px 30px;
    background: url('./bg.jpg') no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
}

.topStrip {
    height: 50px;
    color: white;
    flex-shrink: 0;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .topStrip {
        flex-direction: column;
        height: auto;
        margin-bottom: 20px;
        align-items: flex-start;
    }
}

.buttons {
    display: flex;
    align-items: center;
    margin-left: auto;
}

@media (max-width: 599px) {
    .buttons {
        margin-top: 16px;
        margin-left: 0px    ;
    }
    
}

.endSessionButton:hover {
    background: linear-gradient(1deg, rgb(255, 57, 57) 0%, rgb(194, 0, 0) 100%);
    opacity: 0.8;
}

.goToLoungeButton {
    font-size: 15px;
    background: linear-gradient(1deg, rgb(196, 57, 255) 0%, rgb(136, 85, 255) 100%);
    padding: 12px 16px;
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.899);
    font-weight: 600;
    cursor: pointer;
}

.buttonsDivider {
    background: #7b7b7e;
    width: 1px;
    margin-right: 16px;
    margin-left: 16px;
}

.header {
    font-size: 24px;
    font-weight: 700;
}

.smallHeader {
    display: flex;
    font-size: 15px;
    color: var(--primary);
    font-weight: 600;
    margin-bottom: 10px;
}

.videoContainer {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.frameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-45deg, #005e45, #086171, #6f0874, #9a2d0b);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    border-radius: 30px;
    padding: 3px;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.iframe {
    border: 0;
    border-radius: 30px;
    width: 100%;
    height: 100%;
}

.hideiFrame {
    display: none;
}

.loading {
    font-size: 24px;
    font-weight: 700;
    color: white;
}

.aspectRatio {
    height: 100%;
    padding-bottom: 56.25%;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.liveIndicator {
    display: flex;
    font-size: 14px;
    margin-right: 12px;
    align-items: center;
}

.liveDot {
    margin-right: 4px;
    background: rgb(1, 181, 58);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    animation: opacityAnimation 0.8s alternate-reverse infinite;
}

@keyframes opacityAnimation {
    0% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}

.explainerMessage {
    font-weight: 600;
}