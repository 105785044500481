.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.participantsGrid {
    display: grid;
    flex-direction: row;
    grid-template-columns: 1fr 1fr;
    /* justify-content: center;
    align-items: center; */
    width: 100%;
    height: 100%;
    padding: 12px;
    gap: 8px;
}

@media (max-width: 599px) {
    .participantsGrid {
        grid-template-columns: 1fr;
    }
}


.participantsGrid>* {
    height: 100%;
    width: 100%;
    line-height: 1.6;
    border-radius: 5px;
    /* border: 1px #171718 solid; */
}

.screenShareMode {
    display: flex;
    flex-direction: row;
    z-index: 10;
    justify-content: center;
    height: auto;
}

.screenShareMode > * {
    width: 100px;
    height: 100px;
    margin-right: 8px;
}

.screenShareContainer {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 8px;
}

.shareScreenVideo {
    position: absolute;
    border-radius: 5px;
    object-position: center center;
    z-index: 1;
    object-fit: contain;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.454);
}




/* .participantsGrid>*::after {
    content: "";
    display: block;
    padding-bottom: 80%;
} */

.buttonStripContainer {
    margin-top: -8px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonStrip {
    height: 90px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

@media (max-width: 599px) {
    .buttonStrip {
        flex-wrap: wrap;
        height: auto;
        padding: 12px 0px;
    }
}


@media (max-width: 599px) {
    .buttonStrip > * {
        margin-top: 8px;
    }
}


.leaveTableButton {
    cursor: pointer;
    /* position: absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    background-color: var(--group-video-button-background);
    color: #e25549;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
}

.endMeetingButton {
    background-color: #e25549 !important;
    font-weight: 600;
}

.endMeetingButton:hover {
    transition: 0.3s;
    background-color: var(--group-video-button-background-hover);
}

.stripButton {
    padding: 16px 20px;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--group-video-button-background);
    margin-left: 8px;
    transition: 0.3s;
}

.stripButton:hover {
    background-color: var(--group-video-button-background-hover);
}

.stripButtonDisabled {
    background: var(--red);
}

.stripButtonDisabled:hover {
    background-color: var(--red);
}

.stripButtonIcon {
    font-size: 18px;
}

.noVideoIcon {
    width: 20px;
}

.waitingForOthersContainer {
    background-color: rgba(0, 0, 0, 0.262);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    flex-direction: column;
}

.waitingForOthersHeader {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
}

.settingsButton {
    position: absolute;
    right: 12px;
}

@media (max-width: 599px) {
    .settingsButton {
        position: static;
    }
}


.sendMessageContainer {
    width: 300px;
}

.sendMessageButtonContainer {
    display: flex;
    justify-self: center;
    align-items: center;
}

.sendMessageText {
    font-size: 12px;
    color: var(--text-secondary-color);
    margin-left: 8px;
}

.myNotesButton {
    position: absolute;
    left: 12px;
}


@media (max-width: 599px) {
    .myNotesButton {
        position: static;
    }
}


.notesContainer {
    width: 100%;
    max-width: 500px;
    line-height: 1.6;
}

.notesHeader {
    font-size: 18px;
    font-weight: 700;
}

.notesDescription {
    margin-top: 4px;
    font-size: 14px;
    color: var(--text-secondary-color);
}

.notesInput {
    margin-top: -8px;
    background-color: white !important;
}

.notesSavedBadge {
    opacity: 0;
    position: absolute;
    bottom: 22px;
    z-index: 4;
    right: 8px;
    transition: 0.3s ease;
}

.notesSavedBadge:hover {
    opacity: 0;
}
