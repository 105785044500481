
.companySearchHit {
    display: flex;
    align-items: center;
    height: 40px;
}

.companySearchName {
    font-weight: 600;
}

.companySearchLogo {
    margin-right: 12px;
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 2px;
    border: 1px #f2f2f2 solid;
}

.referrerUserContainer {
    margin-top: 20px;
    display: flex;
}

.referrerUserContainer div {
    margin-left: 12px;
    font-size: 15px;
    color: var(--text-secondary-color);
}

.addNewButtonLink {
    padding: 8px 0px;
    font-weight: 600;
    color: var(--primary);
}

.sideBySide {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sideBySide > * {
    margin-right: 16px;
}

.subHeader {
    font-weight: 600;
    color: var(--dark-black);
    margin-bottom: 14px;
}
