.container {
    display: flex;
    min-height: 100vh;
    width: 100%;
    background: url('./components/stage/bg.jpg') no-repeat;
    background-size: cover;
}

@media (max-width: 599px) {
    .container {
        position: fixed;
        min-height: 100%;
        overflow: hidden;
    }
}

.content {
    position: relative;
    flex: auto;
    height: 100%;
}

@media (max-width: 599px) {
    .content {}
}

.sider {
    /* height: 100%; */
    right: 0;
    /* position: fixed; */
    flex-shrink: 0;
    background-color: white;
    z-index: 10;
    border-left: 1px solid var(--kinky-grey);
    padding: 20px;
    width: auto !important;
    max-width: 100% !important;
    min-width: auto !important;
    flex: initial !important;
    
}

@media(max-width: 599px) {
    .sider {
        /* position: fixed; */
        height: 100vh;
        /* right: 0px; */
    }
}

.loadingContainer {
    position: absolute;
    width: 400px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.loadingHeader {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.4;
    text-align: center;
    color: white;
}

.doneAnimation {
    margin-bottom: 30px;
    color: white;
    width: 140px;
}

.newAttendeeNotification {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
}

.newAttendeeNotification>* {
    margin-right: 18px;
}

.messageNotification {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.messageNotificationTexts {
    margin-left: 12px;
}

.messageNotificationTitle {
    font-weight: 600;
}

.mobileHandler {
    padding: 0px;
    position: absolute;
    right: 0;
    background-color: #f4f6fc;
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);
    top: calc(var(--header-size) + 10px);
    z-index: 999999;
}

.startSessionButton {
    background: linear-gradient(1deg, rgb(255, 248, 57) 0%, rgb(255, 167, 85) 100%);
    font-size: 15px;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.927);
    font-weight: 600;
    cursor: pointer;
    border: none;
    user-select: none;
    opacity: 1;
    transition: 0.3s;
}

.startSessionButton:hover {
    background: linear-gradient(1deg, rgb(255, 248, 57) 0%, rgb(255, 167, 85) 100%);
    color: rgba(0, 0, 0, 0.927);
    opacity: 0.8;
}

.endSessionButton {
    border: none;
    background: linear-gradient(1deg, rgb(255, 57, 57) 0%, rgb(194, 0, 0) 100%);
    font-size: 15px;
    border-radius: 5px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    user-select: none;
    opacity: 1;
    transition: 0.5s;
}

.endSessionButton:hover {
    background: linear-gradient(1deg, rgb(255, 57, 57) 0%, rgb(194, 0, 0) 100%);
    opacity: 0.8;
}

.modalHeader {
    color: var(--dark-black);
    font-weight: 600;
}

.modalText {
    margin-top: 8px;
    color: var(--text-secondary-color);
}

.buttonsDivider {
    background: #7b7b7e;
    width: 1px;
    height: 40px;
    margin-right: 20px;
    margin-left: 20px;
}

.addTableButton {
    color: white;
    border-color: white;
    background-color: transparent;
    transition: 0.3;
}

.addTableButton:hover {
    color: white;
    border-color: white;
    background-color: rgba(255, 255, 255, 0.084);
}

:global .user-online .ant-notification-notice-message {
    margin-bottom: 0 !important;
}

.userOnline {
    padding: 8px !important;
    /* background-color: rgba(255, 255, 255, 0.598); */
}

.feedbackCard {
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding: 0px 10px;
    width: 100%;
    max-width: 360px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 599px) {
    .feedbackCard {
        bottom: 20px;
        left: 10px;
        /* right: auto; */
    }
}

.hideFeedbackButton {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    padding: 8px;
    color: var(--text-secondary-color);
}