
.content {
    display: flex;
    justify-content: center;
    margin-top: -20px;
}

.sectorsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.sectorsContainer > * {
    margin-top: 10px;
}

