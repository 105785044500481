.content {
    max-width: 600px;
    width: 100%;
    margin-top: -24px;
}

.doubleInputGrid {
    margin-top: -24px;
    display: grid;
    column-gap: 20px;
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 599px) {
    .doubleInputGrid {
        row-gap: 0px;;
        grid-template-columns: 1fr;
    }
}

.userProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.profileLabels {
    margin-left: 24px;
}

.profileHeaderText {
    font-weight: bold;
}

.profileSubHeaderText {
    color: var(--text-secondary-color);
}

.companySearchHit {
    display: flex;
    align-items: center;
    height: 40px;
}

.companySearchName {
    font-weight: 600;
}

.companySearchLogo {
    margin-right: 12px;
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 2px;
    border: 1px #f2f2f2 solid;
}

.addNewButtonLink {
    padding: 8px 0px;
    font-weight: 600;
    color: var(--primary);
}

.consent {
    display: flex;
    flex-direction: center;
    justify-content: center;
}

.noCompanySelected {
    margin-top: 8px;
    font-size: 14px;
    color: red;
}