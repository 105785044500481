.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 70px;
}

.content {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 680px;
    width: 100%;
}

@media(max-width: 599px) {
    .content {
        margin-top: 35px;
    }
}






