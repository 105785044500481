.content {
    width: 100%;
    margin-top: -20px;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 599px) {
    .content {
        margin-top: initial;
    }
}

.objectiveButton {
    text-align: center;
    overflow: hidden;
    flex-direction: column;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 14px;
    color: var(--primary);
    font-weight: 600;
    border: 1px var(--kinky-grey) solid;
    border-radius: 5px;
    width: 100%;
    line-height: 1.4;
    max-width: 400px;
    height: 150px;
    transition: 0.3s;
    user-select: none;
    font-size: 14px;
    cursor: pointer;
}

.objectiveButton:hover {
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);
}

.objectivesContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    grid-template-rows: auto;
}

@media (max-width: 599px) {
    .objectivesContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        grid-template-rows: auto;
    }
}

.objectiveSelected {
    background-color: var(--primary-contrast-light);
    font-weight: 600;
}

.icon {
    margin-bottom: 16px;
    width: 40px;
}