.content {
    display: flex;
    flex-direction: column;
}

.header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
}

.settingsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .settingsContainer {
        flex-direction: column;
    }
}


.select {
    /* margin-bottom: 12px; */
    width: 100%;
}

.video {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 30px;
    transform: rotateY(180deg);
}

@media (max-width: 599px) {
    .video {
        width: 150px;
        height: 150px;
        margin-right: 0px;
        margin-bottom: 10px;
    }
}


.devices {
    max-width: 300px;
}