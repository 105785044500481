.container {
    
}

.stripWrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 2px 0;
    position: relative;
    height: 250px;
}

.stripWrapper::-webkit-scrollbar {
    display: none;
  }


.strip {
    -webkit-overflow-scrolling: touch;
    display: flex;
    padding-top: 12px;
    position: absolute;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.strip::-webkit-scrollbar {
    display: none;
  } 
  
.strip > * {
    margin-right: 12px;
}

.stripItem {
    width: 220px;
    height: 230px;
}