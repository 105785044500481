.container {
    display: inline-block;
    position: relative;
}

.small {
    transform: scale(1);
}

.medium {
    transform: scale(1.33);
}

.large {
    transform: scale(1.66);
}

.userImage {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px white solid;
}

.nonImage {
    width: 60px;
    height: 60px;
    background-color: var(--primary-contrast-light);
    border-radius: 50%;
    border: 1px white solid;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25%;
}

.noProfileImage {
    opacity: 0.4;
    width: 100%;
    height: 100%;
}

.companyImage {
    object-fit: contain;
    position: absolute;
    border-radius: 50%;
    border: 1px white solid;
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);
    background-color: #ffffff;
    width: 24px;
    height: 24px;
    right: 0px;
    bottom: 0px;
}

.onlineDot {
    position: absolute;
    left: 3px;
    bottom: 0;
    width: 10px;
    height: 10px;
    border: 1px white solid;
    border-radius: 50%;
    background-color: #30a24c;
}

.onlineDotRipple {
    position: absolute;
    bottom: 0;
    left: 3.4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    animation: ripple 1.4s infinite ease-in-out;
    border: 1px solid #30a24c;
    content: '""',
}

@keyframes ripple {
    0% {
        transform: scale(.8);
        opacity: 1;
    }
    1% {
        transform: scale(.8);
        opacity: 1;
    }
    2% {
        transform: scale(.8);
        opacity: 1;
    }
    3% {
        transform: scale(.8);
        opacity: 1;
    }
    100% {
        transform: scale(1.4);
        opacity: 0;
    }
}