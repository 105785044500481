.container {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.card {
    margin-top: 20px;
    position: relative;
}

.headerContainer  {
    position: relative;
    display: flex;
    background-color: var(--background-color);
}

@media (max-width: 599px) {
    .headerContainer {
        flex-direction: column;
    }
}

.updateButton {
    width:  200px;
    margin-top: 20px;
    margin-left: auto;
}

@media (max-width: 599px) { 
    .updateButton {
        width: 100%;
        margin-left: 0px;
    }
}

.viewProfileButton {
    margin-top: 8px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
}


.doubleInputGrid {
    margin-top: -15px;
    display: grid;
    column-gap: 20px;
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 599px) {
    .doubleInputGrid {
        grid-template-columns: 1fr;
    }
}

.userProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profileLabels {
    margin-left: 24px;
}

.profileHeaderText {
    font-weight: bold;
}

.profileSubHeaderText {
    color: var(--text-secondary-color);
}

.companySearchHit {
    display: flex;
    align-items: center;
    height: 40px;
}

.companySearchName {
    font-weight: 600;
}

.companySearchLogo {
    margin-right: 12px;
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 2px;
    border: 1px #f2f2f2 solid;
}

.addNewButtonLink {
    padding: 8px 0px;
    font-weight: 600;
    color: var(--primary);
}


:global .profile textarea {
    min-height: 140px;
    font-weight: 600 !important;
}

:global .profile .ant-select-selection-placeholder {
    padding-top: 0px !important;
  }
  
  :global .profile .ant-select-selection-item {
    padding-top: 0px !important;
  }
  
  :global .profile .ant-select-selector {
    padding: 5px;
    height: auto;
    min-height: 60px;
  }

  .sectorTag {
    font-size: 14px;
    background-color: var(--primary-contrast-light);
    padding: 8px;
    border: 1px solid #c4d0cf;
    color: var(--primary);
    margin-bottom: 2px;
}

.header {
    font-size: 18px; 
    color: var(--dark-black);
    font-weight: 700;
    margin-bottom: 30px;
}

.subHeader {
    margin-top: 4px;
    font-weight: 400;
    line-height: 1.4;
    font-size: 16px;
    color: var(--text-secondary-color);
}

.objectiveButton {
    text-align: center;
    overflow: hidden;
    flex-direction: column;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 14px;
    color: var(--primary);
    font-weight: 600;
    border: 1px var(--kinky-grey) solid;
    border-radius: 5px;
    width: 100%;
    line-height: 1.4;
    max-width: 400px;
    height: 150px;
    transition: 0.3s;
    user-select: none;
    cursor: pointer;
}

.objectiveButton:hover {
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);
}

.objectivesContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
    grid-template-rows: auto;
}

@media (max-width: 599px) {
    .objectivesContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        grid-template-rows: auto;
    }
}

.objectiveSelected {
    background-color: var(--primary-contrast-light);
    font-weight: 600;
}

.icon {
    margin-bottom: 16px;
    width: 40px;
}

.goBack {
    background: linear-gradient(1deg, rgb(255, 248, 57) 0%, rgb(255, 167, 85) 100%);
    font-size: 15px;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.927);
    font-weight: 600;
    cursor: pointer;
    border: none;
    user-select: none;
    opacity: 1;
    padding: 12px 12px;
    width: fit-content;
    margin-top: -20px;
    margin-bottom: 40px;
    transition: 0.3s;
}

.labelDescription {
    font-size: 14px;
    color: var(--text-secondary-color);
    margin-top: -12px;
}

.reauthContainer {
    display: flex;
    flex-direction: column;
    /* margin-top: -12px; */
}

.reloginButtton {
    margin-top: 8px;
    width: fit-content;
}