.container {
    display: flex;
    flex-direction: column;
    height: auto;
}

.participantsContainer {
    display: grid;
    flex-direction: row;
    grid-template-columns: 1fr 1fr;
    flex-wrap: nowrap;
    column-gap: 20px;
}

@media (max-width: 599px) {
    .participantsContainer {
        grid-template-columns: 1fr;
        row-gap: 20px;
        column-gap: 0px;
    }
}

.buttonsContainer {
    margin-top: 30px;
}

.infoContainer {
    margin-top: 38px;
}

.downtown {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.uptown {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.waitingContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #E8E3FF;
    flex-direction: column;
    font-size: 18px;
    font-weight: 600;
}

.cancelMeetingButton {
    margin-top: 24px;
    font-size: 12px;
    color: white;
    text-decoration: underline;
    cursor: pointer;
    opacity: 0.9;
}

.loadingAnimation {
    margin-bottom: 22px;
    width: 150px;
}
