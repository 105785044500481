.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content { 
    background-color: white;
    padding: 24px;
    border-radius: 20px;
    display: flex;
    width: 100%;
    max-width: 600px;
    margin: 10px;
    flex-direction: column;
    align-items: center;
}

.header {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 30px;
    color: var(--dark-purple);
}

.joinMeetingButton {
    margin-top: 30px;
    /* width: fit-content; */
    padding: 0px 40px;
    /* margin-right: auto;
    margin-left: auto; */
    transform: scale(1.2);
    font-weight: 800;
}