.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    padding: 34px 24px;
    background-color: #18161d;
    border-radius: 15px;
    line-height: 1.4;
}

@media (max-width: 599px) {
    .container {
        grid-template-columns: 1fr;
        column-gap: 0px;
        row-gap: 40px;
    }
}

.header {
    font-size: 18px;
    color: white;
    font-weight: 700;
}

.text {
    color: #E8E3FF;
}

.bio {
    margin-top: 12px;
    color: #E8E3FF;
    line-height: 1.5;
}

.subHeader {
    font-size: 16px;
    color: #E8E3FF;
    font-weight: 700;
}

.comapnyInfo {
    margin-top: 24px;
    color: #E8E3FF;
}

.industryTags {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
}

.industryTags>* {
    margin-right: 6px;
    margin-bottom: 6px;
}

.tag {
    background-color: #008571 !important;
    color: white !important;
    font-size: 14px !important;
    border-radius: 2px;
}