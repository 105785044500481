.container {
    max-width: var(--max-width-default);
}

.content {
    margin-top: 30px;
}

.sideBySide {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

@media (max-width: 1200px) {
    .sideBySide {
        display: grid;
        grid-template-columns: 1fr;
        gap: 12px;
    }    
}


.card {
    user-select: none;
    margin-top: 12px;;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.cardHolder {
    position: relative;
}

.cardHeader{
    font-weight: 700;
    font-size: 16px;
    color: var(--dark-black);
}

.createIntroButton {
    position: absolute; 
    right: 20px;
    top: 20px;
    /* margin-top: 12px; */
    font-weight: bold !important;
}

.introList {
    display: flex;
    flex-direction: column;
    margin: 16px 0px;
    overflow-y: auto;
    padding: 10px 0px;
    height: 320px;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.introList::-webkit-scrollbar {
    display: none;
}

.introItem {
    display: flex;
    flex-direction: row;
    width: 100%;
    user-select: none;
    align-items: center;
}

.meetingItem {
    composes: introItem;
    cursor: pointer;
}

.introTitle {
    margin-left: 8px;
    color: var(--soft-black);
    /* font-size: 15px; */
    /* color: var(--text-secondary-color); */
    /* font-weight: 600; */
}

.teamAvatar {
    width: 24px;
    height: 24px;
}

.introStatusTag {
    font-size: 12px !important;
    font-weight: 600;
    margin-left: auto;
}

.introName {
    font-weight: 700;
    color: var(--soft-black);
    cursor: pointer;
}

:global .intro-list .ant-list-item-action {
    margin-left: 8px;
}

.deleteIntroButton {
    color: var(--red);
    font-weight: 600;
}

.moreItem {
    padding: 4px;
    /* box-shadow: rgba(180, 180, 180, 0.708) 0px 0px 5px; */
    display: flex;
    justify-content: center;
    font-weight: 800;
    font-size: 18px;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
}

.meetingStatus {
    font-size: 12px !important;
    font-weight: 600;
    margin-left: auto;
}

.meetingHeader {
    display: flex;   
    flex-shrink: 0;
    /* justify-content: center; */
    align-items: center;
    margin-left: 8px;
    color: var(--soft-black);
} 
