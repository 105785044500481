.container {
    
}

.header {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 700;
}


.content {
    padding: 0px 0px;
    /* width: 900px; */
    min-height: auto;    
}

.teamAvatar {
    width: 60px;
    height: 60px;
}

.itemContent {
    display: flex;
}

.itemContent > * {
    margin-right: 8px;
}

.userTitle {
    font-weight: 600;
    cursor: pointer;
}

.detailsContainer {

    margin-bottom: 40px;
}

.date {
    color: var(--text-secondary-color);
}

.meetingLink {
    margin-top: 18px;
}

.subHeader {
    font-weight: 600;
    margin-bottom: 4px;
}

.link {
    margin-top: 4px;
    color: var(--text-secondary-color);

}