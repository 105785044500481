@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import "~animate.css/animate.css";

:root {
  --dark-black: #303049;
  --soft-black: #39364F;
  --darker-black: #272738;
  --background-color: #f4f6fb;
  --primary: #1CBEC0;
  --primary-contrast-light: #ecfcfa;
  --primary-contrast-dark: #0a6a6c;
  --text-color: #262c38;
  --text-secondary-color: #6f7987;
  --headers-color: #181d26;
  --link-color: var(--primary);
  --greyish-brown: #3d3d3d;
  --dark-grey: #454d5b;
  --clear-blue: #2979ff;
  --bland-grey: #878787;
  --blue-grey: #f2f8fc;
  --very-light-blue: #dceffc;
  --semi-black: #363636;
  --kinky-grey: #edebeb;
  --easy-grey: #e5e7eb;
  --green: #06c2a6;
  --ok-green: #26cf95;
  --red: #ee1949;
  --dark-purple: #303049;
  --gold: #caa83c;
  --purple: #a42ece;
  --soft-purple: #fbebff;
  --material-color-red: #f44336;
	--material-color-red-50: #ffebee;
	--material-color-red-100: #ffcdd2;
	--material-color-red-200: #ef9a9a;
	--material-color-red-300: #e57373;
	--material-color-red-400: #ef5350;
	--material-color-red-500: var(----material-color-red);
	--material-color-red-600: #e53935;
	--material-color-red-700: #d32f2f;
	--material-color-red-800: #c62828;
	--material-color-red-900: #b71c1c;
	--material-color-red-a100: #ff8a80;
	--material-color-red-a200: #ff5252;
	--material-color-red-a400: #ff1744;
	--material-color-red-a700: #d50000;

	--material-color-pink: #e91e63;
	--material-color-pink-50: #fce4ec;
	--material-color-pink-100: #f8bbd0;
	--material-color-pink-200: #f48fb1;
	--material-color-pink-300: #f06292;
	--material-color-pink-400: #ec407a;
	--material-color-pink-500: var(--material-color-pink);
	--material-color-pink-600: #d81b60;
	--material-color-pink-700: #c2185b;
	--material-color-pink-800: #ad1457;
	--material-color-pink-900: #880e4f;
	--material-color-pink-a100: #ff80ab;
	--material-color-pink-a200: #ff4081;
	--material-color-pink-a400: #f50057;
	--material-color-pink-a700: #c51162;

	--material-color-purple: #9c27b0;
	--material-color-purple-50: #f3e5f5;
	--material-color-purple-100: #e1bee7;
	--material-color-purple-200: #ce93d8;
	--material-color-purple-300: #ba68c8;
	--material-color-purple-400: #ab47bc;
	--material-color-purple-500: var(--material-color-purple);
	--material-color-purple-600: #8e24aa;
	--material-color-purple-700: #7b1fa2;
	--material-color-purple-800: #6a1b9a;
	--material-color-purple-900: #4a148c;
	--material-color-purple-a100: #ea80fc;
	--material-color-purple-a200: #e040fb;
	--material-color-purple-a400: #d500f9;
	--material-color-purple-a700: #a0f;

	--material-color-deep-purple: #673ab7;
	--material-color-deep-purple-50: #ede7f6;
	--material-color-deep-purple-100: #d1c4e9;
	--material-color-deep-purple-200: #b39ddb;
	--material-color-deep-purple-300: #9575cd;
	--material-color-deep-purple-400: #7e57c2;
	--material-color-deep-purple-500: var(--material-color-deep-purple);
	--material-color-deep-purple-600: #5e35b1;
	--material-color-deep-purple-700: #512da8;
	--material-color-deep-purple-800: #4527a0;
	--material-color-deep-purple-900: #311b92;
	--material-color-deep-purple-a100: #b388ff;
	--material-color-deep-purple-a200: #7c4dff;
	--material-color-deep-purple-a400: #651fff;
	--material-color-deep-purple-a700: #6200ea;

	--material-color-indigo: #3f51b5;
	--material-color-indigo-50: #e8eaf6;
	--material-color-indigo-100: #c5cae9;
	--material-color-indigo-200: #9fa8da;
	--material-color-indigo-300: #7986cb;
	--material-color-indigo-400: #5c6bc0;
	--material-color-indigo-500: var(--material-color-indigo);
	--material-color-indigo-600: #3949ab;
	--material-color-indigo-700: #303f9f;
	--material-color-indigo-800: #283593;
	--material-color-indigo-900: #1a237e;
	--material-color-indigo-a100: #8c9eff;
	--material-color-indigo-a200: #536dfe;
	--material-color-indigo-a400: #3d5afe;
	--material-color-indigo-a700: #304ffe;

	--material-color-blue: #2196f3;
	--material-color-blue-50: #e3f2fd;
	--material-color-blue-100: #bbdefb;
	--material-color-blue-200: #90caf9;
	--material-color-blue-300: #64b5f6;
	--material-color-blue-400: #42a5f5;
	--material-color-blue-500: var(--material-color-blue);
	--material-color-blue-600: #1e88e5;
	--material-color-blue-700: #1976d2;
	--material-color-blue-800: #1565c0;
	--material-color-blue-900: #0d47a1;
	--material-color-blue-a100: #82b1ff;
	--material-color-blue-a200: #448aff;
	--material-color-blue-a400: #2979ff;
	--material-color-blue-a700: #2962ff;

	--material-color-light-blue: #03a9f4;
	--material-color-light-blue-50: #e1f5fe;
	--material-color-light-blue-100: #b3e5fc;
	--material-color-light-blue-200: #81d4fa;
	--material-color-light-blue-300: #4fc3f7;
	--material-color-light-blue-400: #29b6f6;
	--material-color-light-blue-500: var(--material-color-light-blue);
	--material-color-light-blue-600: #039be5;
	--material-color-light-blue-700: #0288d1;
	--material-color-light-blue-800: #0277bd;
	--material-color-light-blue-900: #01579b;
	--material-color-light-blue-a100: #80d8ff;
	--material-color-light-blue-a200: #40c4ff;
	--material-color-light-blue-a400: #00b0ff;
	--material-color-light-blue-a700: #0091ea;

	--material-color-cyan: #00bcd4;
	--material-color-cyan-50: #e0f7fa;
	--material-color-cyan-100: #b2ebf2;
	--material-color-cyan-200: #80deea;
	--material-color-cyan-300: #4dd0e1;
	--material-color-cyan-400: #26c6da;
	--material-color-cyan-500: var(--material-color-cyan) ;
	--material-color-cyan-600: #00acc1;
	--material-color-cyan-700: #0097a7;
	--material-color-cyan-800: #00838f;
	--material-color-cyan-900: #006064;
	--material-color-cyan-a100: #84ffff;
	--material-color-cyan-a200: #18ffff;
	--material-color-cyan-a400: #00e5ff;
	--material-color-cyan-a700: #00b8d4;

	--material-color-teal: #009688;
	--material-color-teal-50: #e0f2f1;
	--material-color-teal-100: #b2dfdb;
	--material-color-teal-200: #80cbc4;
	--material-color-teal-300: #4db6ac;
	--material-color-teal-400: #26a69a;
	--material-color-teal-500: var(--material-color-teal);
	--material-color-teal-600: #00897b;
	--material-color-teal-700: #00796b;
	--material-color-teal-800: #00695c;
	--material-color-teal-900: #004d40;
	--material-color-teal-a100: #a7ffeb;
	--material-color-teal-a200: #64ffda;
	--material-color-teal-a400: #1de9b6;
	--material-color-teal-a700: #00bfa5;

	--material-color-green: #4caf50;
	--material-color-green-50: #e8f5e9;
	--material-color-green-100: #c8e6c9;
	--material-color-green-200: #a5d6a7;
	--material-color-green-300: #81c784;
	--material-color-green-400: #66bb6a;
	--material-color-green-500: var(--material-color-green);
	--material-color-green-600: #43a047;
	--material-color-green-700: #388e3c;
	--material-color-green-800: #2e7d32;
	--material-color-green-900: #1b5e20;
	--material-color-green-a100: #b9f6ca;
	--material-color-green-a200: #69f0ae;
	--material-color-green-a400: #00e676;
	--material-color-green-a700: #00c853;

	--material-color-light-green: #8bc34a;
	--material-color-light-green-50: #f1f8e9;
	--material-color-light-green-100: #dcedc8;
	--material-color-light-green-200: #c5e1a5;
	--material-color-light-green-300: #aed581;
	--material-color-light-green-400: #9ccc65;
	--material-color-light-green-500: var(--material-color-light-green);
	--material-color-light-green-600: #7cb342;
	--material-color-light-green-700: #689f38;
	--material-color-light-green-800: #558b2f;
	--material-color-light-green-900: #33691e;
	--material-color-light-green-a100: #ccff90;
	--material-color-light-green-a200: #b2ff59;
	--material-color-light-green-a400: #76ff03;
	--material-color-light-green-a700: #64dd17;

	--material-color-lime: #cddc39;
	--material-color-lime-50: #f9fbe7;
	--material-color-lime-100: #f0f4c3;
	--material-color-lime-200: #e6ee9c;
	--material-color-lime-300: #dce775;
	--material-color-lime-400: #d4e157;
	--material-color-lime-500: var(--material-color-lime);
	--material-color-lime-600: #c0ca33;
	--material-color-lime-700: #afb42b;
	--material-color-lime-800: #9e9d24;
	--material-color-lime-900: #827717;
	--material-color-lime-a100: #f4ff81;
	--material-color-lime-a200: #eeff41;
	--material-color-lime-a400: #c6ff00;
	--material-color-lime-a700: #aeea00;

	--material-color-yellow: #ffeb3b;
	--material-color-yellow-50: #fffde7;
	--material-color-yellow-100: #fff9c4;
	--material-color-yellow-200: #fff59d;
	--material-color-yellow-300: #fff176;
	--material-color-yellow-400: #ffee58;
	--material-color-yellow-500: var(--material-color-yellow);
	--material-color-yellow-600: #fdd835;
	--material-color-yellow-700: #fbc02d;
	--material-color-yellow-800: #f9a825;
	--material-color-yellow-900: #f57f17;
	--material-color-yellow-a100: #ffff8d;
	--material-color-yellow-a200: #ff0;
	--material-color-yellow-a400: #ffea00;
	--material-color-yellow-a700: #ffd600;

	--material-color-amber: #ffc107;
	--material-color-amber-50: #fff8e1;
	--material-color-amber-100: #ffecb3;
	--material-color-amber-200: #ffe082;
	--material-color-amber-300: #ffd54f;
	--material-color-amber-400: #ffca28;
	--material-color-amber-500: var(--material-color-amber);
	--material-color-amber-600: #ffb300;
	--material-color-amber-700: #ffa000;
	--material-color-amber-800: #ff8f00;
	--material-color-amber-900: #ff6f00;
	--material-color-amber-a100: #ffe57f;
	--material-color-amber-a200: #ffd740;
	--material-color-amber-a400: #ffc400;
	--material-color-amber-a700: #ffab00;

	--material-color-orange: #ff9800;
	--material-color-orange-50: #fff3e0;
	--material-color-orange-100: #ffe0b2;
	--material-color-orange-200: #ffcc80;
	--material-color-orange-300: #ffb74d;
	--material-color-orange-400: #ffa726;
	--material-color-orange-500: var(--material-color-orange);
	--material-color-orange-600: #fb8c00;
	--material-color-orange-700: #f57c00;
	--material-color-orange-800: #ef6c00;
	--material-color-orange-900: #e65100;
	--material-color-orange-a100: #ffd180;
	--material-color-orange-a200: #ffab40;
	--material-color-orange-a400: #ff9100;
	--material-color-orange-a700: #ff6d00;

	--material-color-deep-orange: #ff5722;
	--material-color-deep-orange-50: #fbe9e7;
	--material-color-deep-orange-100: #ffccbc;
	--material-color-deep-orange-200: #ffab91;
	--material-color-deep-orange-300: #ff8a65;
	--material-color-deep-orange-400: #ff7043;
	--material-color-deep-orange-500: var(--material-color-deep-orange);
	--material-color-deep-orange-600: #f4511e;
	--material-color-deep-orange-700: #e64a19;
	--material-color-deep-orange-800: #d84315;
	--material-color-deep-orange-900: #bf360c;
	--material-color-deep-orange-a100: #ff9e80;
	--material-color-deep-orange-a200: #ff6e40;
	--material-color-deep-orange-a400: #ff3d00;
	--material-color-deep-orange-a700: #dd2c00;

	--material-color-brown: #795548;
	--material-color-brown-50: #efebe9;
	--material-color-brown-100: #d7ccc8;
	--material-color-brown-200: #bcaaa4;
	--material-color-brown-300: #a1887f;
	--material-color-brown-400: #8d6e63;
	--material-color-brown-500: var(--material-color-brown);
	--material-color-brown-600: #6d4c41;
	--material-color-brown-700: #5d4037;
	--material-color-brown-800: #4e342e;
	--material-color-brown-900: #3e2723;

	--material-color-grey: #9e9e9e;
	--material-color-grey-50: #fafafa;
	--material-color-grey-100: #f5f5f5;
	--material-color-grey-200: #eee;
	--material-color-grey-300: #e0e0e0;
	--material-color-grey-400: #bdbdbd;
	--material-color-grey-500: var(--material-color-grey);
	--material-color-grey-600: #757575;
	--material-color-grey-700: #616161;
	--material-color-grey-800: #424242;
	--material-color-grey-900: #212121;

	--material-color-blue-grey: #607d8b;
	--material-color-blue-grey-50: #eceff1;
	--material-color-blue-grey-100: #cfd8dc;
	--material-color-blue-grey-200: #b0bec5;
	--material-color-blue-grey-300: #90a4ae;
	--material-color-blue-grey-400: #78909c;
	--material-color-blue-grey-500: var(--material-color-blue-grey);
	--material-color-blue-grey-600: #546e7a;
	--material-color-blue-grey-700: #455a64;
	--material-color-blue-grey-800: #37474f;
	--material-color-blue-grey-900: #263238;
  
  --header-size: 64px;
  --max-width-default: 1200px;
}

.app {
  height: 100%;
  width: 100%;
}

html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0px;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark-black);
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 1 !important;
}

a {
  color: var(--primary);
}

.white-button {
  color: white !important;
  border-color: white !important;
  background-color: transparent !important;
  transition: 0.3s !important;
}

.white-button:hover {
  color: white !important;
  border-color: white !important;
  background-color: rgba(255, 255, 255, 0.084) !important;
}

.white-button:focus {
  background-color: transparent !important;
}

.underlinedLink{
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: #b5b5b5;
  text-decoration-style: dotted;
  cursor: pointer;
}

.loaderContainer{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}


/* Overrides */

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #ebebeb;
  color: #2a3038;
}

.ant-menu-item a {
  font-size: 15px;
  font-weight: 600;
}

.ant-menu-item-selected a {
  color: #2a3038;
  font-weight: 700;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid var(--primary);
}

.ant-menu-item a:hover {
  color: var(--primary);
}

.ant-menu-item:hover {
  color: var(--primary);
}



.ant-btn {
  color: var(--primary);
  border-color: var(--primary);
  text-shadow: initial;
}

.ant-btn:hover {
	border-color: var(--primary-contrast-dark);
	color: var(--primary-contrast-dark);;
}

.ant-btn-link {
  border: none;

}

.ant-btn-primary {
  background: var(--primary);
  border-color: var(--primary);
  color: white;
  font-weight: 600;
}

.ant-btn-dangerous {
  color: #ff4d4f;
  background: #fff;
  border-color: #ff4d4f;
}


.ant-btn-text:hover, .ant-btn-text:focus {
  color: unset;
  background: transparent;
  border-color: none;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  background: var(--primary-contrast-dark);
  border-color: transparent;
  color: white;
}

.ant-input, .ant-select-selector, .ant-input-number-input, .ant-input-number, .ant-picker, .ant-input-affix-wrapper{
  background-color: #f5f8fa !important;
  border-radius: 5px !important;
  border-color: transparent !important;
  height: 60px;
  color: #3c3c3c !important;
  font-weight: 700 !important;
  font-size: 16px !important;
}

.ant-input::placeholder{
	color: rgb(138, 138, 138);
}

.ant-input-affix-wrapper > input.ant-input {
  height: auto;
}

.ant-picker {
  width: 100%;
}

.ant-picker-input > input {
  font-size: 16px;
  font-weight: 700;
}

.hide-resize textarea {
  vertical-align:middle; 
  display: table-cell;
  resize: none;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary);
    border-color: var(--primary);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: var(--primary) !important;
}


.ant-input:hover, .ant-input:focus {
  border-color: var(--primary);
}

.ant-form-item-label > label {
  font-size: 14px;
  color: #a4a4a4;
  font-weight: 700;
}

.ant-form-item-label > label.ant-form-item-required:before {
  content: unset !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 100%;
  color: #3c3c3c;
  font-weight: 700;
  font-size: 16px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 60px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 60px;
}

.ant-drawer-title {
  font-weight: 700;
}

.ant-input-group-addon {
  background-color:#f5f8fa;
  border: unset;
  color: #0000007c;
  font-weight: 600;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)  {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.doubleInputGrid {
  display: grid;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr;
}

.sectors .ant-tag-checkable {
  padding: 12px 20px;
  color: var(--primary);
  font-size: 16px;
  border-radius: 3px;
  border: 1px var(--primary) solid;
  user-select: none;
}
.sectors .ant-tag-checkable-checked {
  background-color: var(--primary-contrast-light);
  color: var(--primary);
  font-weight: 700;
  border: 2px var(--primary) solid;
  padding-right: 12px;
  padding-left: 12px;
}

.sectors .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: var(--primary-contrast-dark);
  border: 1px var(--primary-contrast-dark) solid;
} 

.ant-select-selection-item {
    padding-top: 10px !important;
}

.ant-select-selection-placeholder {
    padding-top: 10px !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  font-weight: 700;
}

.ant-layout {
  background-color: var(--background-color);
}

a.ant-typography, .ant-typography a {
  color: var(--primary);
}

a.ant-typography, .ant-typography a:hover {
  color: var(--primary-contrast-dark);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn  {
  font-weight: 700;
  color: var(--primary);
}

.ant-tabs-ink-bar {
  background: var(--primary);
}

.ant-select-selection-item {
  padding-top: 10px !important;
}

.ant-select-selection-placeholder {
  padding-top: 10px !important;
}

.ant-modal-title   {
  font-weight: 700;
}

.ant-slider-track {
  background-color: var(--primary);
}

.ant-slider:hover .ant-slider-track { 
  background-color: var(--primary);
}

.ant-slider-handle.ant-tooltip-open {
  border-color: var(--primary);
}

.ant-switch-checked { 
  background-color: var(--primary);
}

.ant-timeline-item-head-blue {
  color: var(--primary);
  border-color: var(--primary);
}


.ant-carousel .slick-dots li.slick-active button {
  background: var(--primary);
  opacity: 1;
}

.ant-carousel .slick-dots li button {
  background: var(--primary);
  opacity: 0.3;
  /* color: var(--primary); */
}

.ant-carousel .slick-dots  {
  bottom: 0;
}

.ant-rate-star-first, .ant-rate-star-second {
  color: #e8e8e8;
}

[data-aspect-ratio] {
  display: block;
  max-width: 100%;
  position: relative;
}

[data-aspect-ratio]:before {
  content: '';
  display: block;
}

[data-aspect-ratio] > * {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
[data-aspect-ratio="3:1"]:before {
  padding-top: 33.33%;
}
[data-aspect-ratio="2:1"]:before {
  padding-top: 50%;
}
[data-aspect-ratio="16:9"]:before {
  padding-top: 56.25%;
}
[data-aspect-ratio="3:2"]:before {
  padding-top: 66.66%;
}
[data-aspect-ratio="4:3"]:before {
  padding-top: 75%;
}



/* icons */

@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot?occdc7');
  src:  url('./fonts/icomoon.eot?occdc7#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?occdc7') format('truetype'),
    url('/fonts/icomoon.woff?occdc7') format('woff'),
    url('./fonts/icomoon.svg?occdc7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ic-tables:before {
  content: "\e1";
}
.icon-ic-admin:before {
  content: "\e2";
}
.icon-ic-help:before {
  content: "\e3";
}
.icon-ic-lounge:before {
  content: "\e4";
}
.icon-ic-stage:before {
  content: "\e5";
}
.icon-ic-people:before {
  content: "\e6";
}
.icon-ic-people2:before {
  content: "\e7";
}
