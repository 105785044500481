.content {
    color: var(--text-secondary-color);
    line-height: 1.6;
    font-size: 16px;
    padding: 30px 24px;
    display: grid;
    gap: 60px;
}

@media(max-width: 599px) {
    .content {
        padding: 30px 12px;
    }
}

.industryTag {
    font-size: 14px;
    background-color: #ecfcfa;
    padding: 8px;
    border: 1px solid #c4d0cf;
    color: var(--primary);
    margin-bottom: 2px;
}

.companyLogo {
    width: 100px;
    object-fit: contain;
    height: 100px;
}

.uploadText {
    font-size: 14px;
    color: var(--text-secondary-color);
}

:global .add-company .ant-select-selection-item {
    padding-top: 10px !important;
}

:global .add-company .ant-select-selection-placeholder {
    padding-top: 10px !important;
}

:global .industries-selector .ant-select-selection-placeholder {
    padding-top: 0px !important;
}

:global .industries-selector .ant-select-selection-item {
    padding-top: 0px !important;
}

:global .industries-selector .ant-select-selector {
    padding: 5px;
    height: auto;
    min-height: 60px;
}

:global .add-company textarea {
    min-height: 140px;
}
