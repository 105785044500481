.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.content {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 40px 60px;
}