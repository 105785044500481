.container {
    display: flex;
    flex-direction: column;
    position: relative;
    /* width: 100%; */
}

.empty {
    height: calc(100vh - 50px);
    padding-top: 20px;
    color: #8e8e8e;
}

.messagesContainer {
    padding: 12px;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-bottom: 50px;
    height: calc(100vh - 50px);
}

@media (max-width: 599px) {
    .messagesContainer {
        height: calc(100vh - 170px);
    }    
}


.messagesContainer::-webkit-scrollbar {
    display: none;
}

.messageBackground{
    background-color: white;
}

.input {
    composes: messageBackground;
    border: none;
    width: 100%;
}

.sendMessageContainer {
    composes: messageBackground;
    display: flex;
    width: 100%;
    margin-top: 20px;
    position: absolute;
    bottom: 0px;
    right: 0;
    left: 0;
    border-top: 1px solid #e9e9e9;
    padding: 10px;
}

.sendButton {
    padding: 5px;
    margin-left: auto;
    margin-left: 18px;
    cursor: pointer;
    color: var(--primary);
}

.notification {
    background: var(--red);
    width: 4px;
    height: 4px;
    margin-left: 5px;
    border-radius: 50%;
}

.tabWithNotification {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    color: #9a9a9d;
    font-weight: 600;
    font-size: 14px;
    padding: 16px 12px;
    border-bottom: 1px solid #f0f0f0;

}

:global .message-container .ant-mentions:hover {
    border-width: 0px !important;
    border-color: transparent !important;
    box-shadow: none;
}

:global .message-container .ant-mentions > textarea {
    background:white;
    
}
:global .message-container .ant-mentions > textarea::placeholder {
    color:#525252de !important;
    
}

:global .message-container .ant-mentions:focus {
    border-width: 0px !important;
    border-color: transparent !important;
    box-shadow: none;
}

:global .message-container .ant-mentions-focused {
    border-width: 0px !important;
    border-color: transparent !important;
    box-shadow: none;
}