.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    line-height: 1.7;
    padding: 12px;
    position: relative;
}

.content{
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 12px 0;
}

.welcomeAnimation {
    margin-top: 50px;
    width: 250px;
}


.welcomePhase {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logoutButton {
    position: absolute;
    left: 20px;
    bottom: 20px;
    font-size: 12px;
    width: fit-content;
    height: fit-content;
    color: var(--text-secondary-color);
}

@media (max-width: 599px) {
    .logoutButton {
        top: 20px;
        right: 20px;
    }
}