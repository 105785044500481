.container {
    padding-top: 2px;
}

@media (max-width: 599px) {
    .container {
        /* margin-right: -12px; */
    }
}

@media (max-width: 599px) {
    .mobileStrip {
        margin-right: -12px;
    }
}

.strips {
    margin-bottom: 30px;
    display: grid;
    row-gap: 38px;
}

.greeting {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    ;
}

@media (max-width: 599px) {
    .greeting .header {
        font-size: 24px;
        font-weight: 700;
    }
}

.greeting .subHeader {
    margin-top: 8px;
    color: var(--text-secondary-color);
}

.membershipCard {
    /* margin-bottom: 20px; */
}