.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center ;
    justify-content: center;
}

.typeButton {
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 34px 12px;
    color: var(--primary);
    font-weight: 700;
    border: 2px var(--primary) solid;
    border-radius: 5px;
    width: 100%;
    max-width: 400px;
    margin-bottom: 12px;
    transition: 0.3s;
    cursor: pointer;
}

.icon {
    position: absolute;
    bottom: -5px;
    width: 40px;
    right: 16px;
}

.typeButton:hover {
    background-color: var(--primary-contrast-light);
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);
}