.container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: var(--max-width-default);
}

.sectionContainer {
    display: flex;
    flex-direction: column;
}

.buttons {
    margin-top: 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.buttons>* {
    margin-right: 8px;
}


.card {
    display: flex;
    flex-direction: column;
    line-height: 1.6em;
    font-size: 16px;
}

.contentContainer{
    margin: 22px 0px;
    height: 100%;
}

.loader {
    position: absolute;
}

.embedContainer {
    width: 100%;
    height: 100%;
    display: flex;
    border: 1px var(--bland-grey) solid;
    justify-content: center;
    background-color: #d1d1d1;
    align-items: center;
}

.embed {
    width: 100%;
    height: 100%;
}


.externalIcon {
    width: 12px;
    margin-right: 4px;
}