.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px;
    border: 1px var(--kinky-grey) solid;
    width: 100%;
}

.content {
    display: flex;
    width: 100%;
    flex-direction: row;
}

@media(max-width: 599px) {
    .content {
        flex-direction: column;
    }
}

.logo {
    margin-right: 24px;
    width: 50px;
    height: 50px;
    border-radius: 3px;
    object-fit: contain;
}

.name {
    font-weight: 700;
}

.unlinkButton {
    font-size: 14px;
    margin-left: auto;
}

@media(max-width: 599px) {
    .unlinkButton {
        margin-top: 4px;
        margin-left: 0px;
    }
}

.label {
    font-size: 14px;
    color: #a4a4a4;
    font-weight: 700;
    margin-bottom: 8px;
}