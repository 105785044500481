:root {
    --group-video-background: #101010f9;
    --group-video-button-background: #302e38;
    --group-video-button-background-hover: #1d1c22;
}

:global .round-tables .ant-modal-content {
    background-color: transparent !important;
}

:global .round-tables .ant-modal-body {
    padding: 10px 0px 5px 0px;
}

.container {
    width: auto !important;
    min-width: 600px !important;
    max-width: auto !important;
    border-radius: 10px !important;
    padding-bottom: 0px;
    padding-right: 12px;
    padding-left: 12px;
    overflow: hidden;
    background-color: var(--group-video-background);
    box-shadow: rgba(0, 0, 0, 0.708) 0px 5px 40px;
    user-select: none;
}

@media (max-width: 599px) {
    .container {
        min-width: initial !important;
        width: initial !important;
        max-width: initial !important;
        border-radius: initial !important;
        padding: initial !important;
        
    }
}

.content {}

.grid {
    display: grid;
    flex-direction: row;
    width: 80vw;
    max-width: 1400px;
    max-height: 85vh;
    gap: 8px;
}

@media (max-width: 599px) {
    .grid {
        display: initial;
        max-height: initial;
        width: 100vw;        
    }
}

.grid>* {
    /* min-width: 250px;
    min-height: 250px; */
    width: auto;
    height: auto;
    border-radius: 5px;
    width: 100%;
    display: flex;
    border: 1px #171718 solid;
    box-shadow: rgba(0, 0, 0, 0.708) 0px 10px 40px;
}

.grid>*::after {
    content: "";
    display: block;
    padding-bottom: 80%;
}

.twoParticipants {
    grid-template-columns: 1fr 1fr;
}

.threeParticipants {
    grid-template-columns: 1fr 1fr 1fr;
}

.fourParticipants {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.fiveParticipants {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.sixParticipants {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.sevenParticipants {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.eightParticipants {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.nineParticipants {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
}

.content {
    display: flex;
    flex-direction: column;
}

.bottomStrip {
    margin-top: 18px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px rgb(34 34 34) solid;
    position: relative;
}

.leaveTableButton {
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    right: 0;
    background-color: var(--group-video-button-background);
    color: #e25549;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
}

.leaveTableButton:hover {
    transition: 0.3s;
    background-color: var(--group-video-button-background-hover);
}

.stripButton {
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--group-video-button-background);
    margin-left: 8px;
    transition: 0.3s;
}

.stripButton:hover {
    background-color: var(--group-video-button-background-hover);
}


.stripButtonDisabled {
    background: var(--red);
}

.stripButtonDisabled:hover {
    background-color: var(--red);
}


.stripButtonIcon {
    font-size: 18px;
}

.tableDetails {
    position: absolute;
    left: 0;
    opacity: 0.5;
}

.tableNumber {
    font-size: 14px;
    font-weight: 700;
    color: #E8E3FF;
}

.tableTitle {
    margin-top: 2px;
    font-size: 12px;
    font-weight: 400;
    color: #E8E3FF;
}

.noVideoIcon {
    width: 20px;
}

.loaderContainer {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingAnimation {
    width: 100px;
}


.divider {
    background: #373737;
    width: 1px;
    height: 28px;
    margin-right: 4px;
    margin-left: 12px;
}