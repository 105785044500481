.container {
    position: relative;
}

.resourcesGrid {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
}

.sectionContainer {
    display: flex;
    flex-direction: column;
}

.resourcesGrid>* {
    margin-right: 15px;
    margin-bottom: 15px;
}

@media(max-width: 599px) {
    .resourcesGrid>* {
        margin-right: 0px;
        margin-bottom: 16px;
        width: 100%;
    }
}

.buttons {
    margin-top: 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.buttons>* {
    margin-right: 8px;
}

.eventsContainer {
    margin-top: 60px;
}
