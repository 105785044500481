.logoContainer {
    width: 43px;
    height: 43px;
    box-shadow: rgba(213, 213, 213, 0.708) 0px 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    background-color: white;
    transition: all 0.3s;
}

.logo {
    width: 20px;
    flex-shrink: 0;
}

.logoContainer:hover {
    transform: scale(1.1);
}