.content {
    display: flex;
    flex-direction: column;
}

.header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
}

.settingsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.select {
    /* margin-bottom: 12px; */
    width: 100%;
}

.video {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.devices {
    width: 100%;
}