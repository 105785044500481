.container {
    
}


.content {
    padding: 0px 0px;
    /* width: 900px; */
    min-height: auto;    
}

.header {
    margin-top: 12px;
    margin-bottom: 6px;
    font-weight: 700;
}

.bubblesContainer {
    margin: 24px 0px 20px 0px;
    display: flex;
    flex-direction: row;
}

.purpose {
    font-size: 15px;
}

.userAvatar {
    margin-right: -8px;
}

.timeline {
    margin-top: 50px;
}

.timelineItemMessage{

}

.timelineItemTime {
    font-size: 12px;
    margin-left: 4px;
    color: var(--text-secondary-color);
}

.names {
    font-size: 16px;
    font-weight: 700;
}

.timelineIcon {
    font-size: 16px;
}


.itemId  {
    font-size: 14px;
    color: var(--text-secondary-color);
    margin-top: 12px;
}