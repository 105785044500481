.container {
    background-color: #222226;
    overflow: hidden;
}

.content {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
} 

.contentSafariBug {
    height: auto !important;
}

.video {
    position: absolute;
    border-radius: 5px;
    object-position: center center;
    z-index: 1;
    transform: rotateY(180deg);
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.videoOverlay {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100px;
    z-index: 2;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}

.userInfo {
    position: absolute;
    bottom: 0;
    padding: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    z-index: 3;
}

.details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.texts {
    margin-left: 14px;
}

.participantName {
    font-weight: 600;
    font-size: 14px;
    color: white;
}

.participantTitle {
    font-size: 12px;
    max-width: 100%;
    color: white;
    margin-bottom: 0 !important;
}

.controlButtons {
    display: flex;
    flex-direction: row;
    justify-self: flex-end;
    align-self: flex-end;
    margin-left: auto;
}

.controlButtons>* {
    margin-left: 2px;
}

.message {
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
}

.messageContainer {
    display: flex;
    padding: 0px 4px 4px 4px;
    font-size: 16px;
    flex-direction: column;
}

.cameraOffContainer {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.companyAvatar {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
}

.controlButton {
    padding: 5px;
}

.youBadge {
    position: absolute;
    z-index: 2;
    top: 12px;
    right: 12px;
    padding: 2px 8px;
    font-size: 12px;
    background-color: var(--ok-green);
    border-radius: 4px;
    color: white;
    font-weight: 700;
}

.infoBadge {
    color: white;
    font-size: 13px;
    margin-left: 6px;
}

.tooltip { 
    /* color: var(--text-secondary-color); */
    font-size: 14px;
}