
.content {
    color: var(--text-secondary-color);
    line-height: 1.6;
    font-size: 16px;
    padding: 38px 48px 40px 48px;
    display: grid;
    gap: 40px;
}

@media(max-width: 599px) {
    .content {
        padding: 20px 10px 10px 20px;
    }
}

.buttonContainer {
    margin-top: 16px;
    margin-left: 170px;
}

.buttonContainer > * {
    margin-right: 8px;
}

@media(max-width: 599px) {
    .buttonContainer > * {
        margin-bottom: 8px;
    }
}

.meetButton {
    font-weight: 600;
}

.introButton {
    font-weight: 600;
}

.metricsButton {
    background-color: var(--darker-black);
    color: white;
    border-color: var(--darker-black);
}

.metricsButton:hover, .metricsButton:focus {
    background-color: var(--darker-black);
    color: white;
    border-color: var(--darker-black);
}

@media(max-width: 599px) {
    .buttonContainer {
        margin-left: 12px;
    }
}


.subHeader {
    font-weight: 700;
    color: #00063c;
    margin-bottom: 24px;
}

.foundingMember {
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    width: fit-content;
    border: red 1px solid;
}

.badges {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.badges > * {
    margin-right: 18px;
    margin-bottom: 18px;
}

.badge {
    display: flex;
    width: 140px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    padding: 12px;
    border: 1px var(--kinky-grey) solid;
    user-select: none;
}

.badge img {
    width: 70px
}

.badge div {
    margin-top: 5px;
    color: #191220;
    font-weight: 600;
    font-size: 12px;
}