.container {
    
}

@media (max-width: 599px) {
    .container {
        
    }
}

.content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 00px;
    width: 100%;
}

.header {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
}

.description {
    color: var(--text-secondary-color);

}

.illustration {
    margin-top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.illustration img {
    width: 200px;
}

.proceedButton {
    margin-top: 30px;
    color: var(--text-secondary-color);
    font-size: 14px;
    text-decoration: underline;
    align-self: center;
    cursor: pointer;
}