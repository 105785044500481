.content {
    padding: 20px;
}

.grid {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr;
}

.form {
    gap: 12px;
    /* background-color: rgb(237, 237, 237); */
    display: grid;
    padding: 20px;
    border: 1px solid var(--material-color-grey-200);
    grid-template-columns: 1fr 1fr;
}

.header {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 8px;
}

.subHeader {
    font-size: 16px;
    color: var(--text-secondary-color);
}

.label {
    font-weight: 700;
    margin-bottom: 12px;
}

.input {
    width: 100%;
}

.inputContainer {
    margin-top: 12px;
    display: flex;
}

.copyButton {
    height: 60px;
}

:global .guest-invite-modal .ant-select-selection-item {
    padding-top: 10px !important;
}

:global .guest-invite-modal .ant-select-selection-placeholder {
    padding-top: 10px !important;
}