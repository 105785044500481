.container {
    width: 100%;
}

.stripWrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 12px 0;
    position: relative;
    height: 360px;
    width: 100%;
}

@media (max-width: 599px) {
    .stripWrapper {
        height: 382px;
        /* background-color: black; */
    }
}

.stripWrapper::-webkit-scrollbar {
    display: none;
  }

.strip {
    -webkit-overflow-scrolling: touch;
    display: flex;
    padding-top: 12px;
    position: absolute;
    width: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

@media (max-width: 599px) {
    .strip {
        width: auto;
    }
    
}

.strip::-webkit-scrollbar {
    display: none;
}

.strip>* {
    margin-right: 16px;
}

@media (max-width: 599px) {
    .stripItem {
        width: 85vw;
        height: 348px;
    }
}

.emptyEvents {
    /* margin-top: 30px; */
    border: 1px solid var(--kinky-grey);
    padding: 20px 40px;
    width: 100%;
    max-width: 850px;
    background-color: #ffffff;
    color: var(--text-secondary-color);
    font-size: 15px;
    line-height: 1.6em;
}

.emptyEventsHeader {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 22px;
}