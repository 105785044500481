.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-height: 1.5;
    text-align: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    
}

.content {
    max-width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.topHeaderText {
    color: var(--primary);
    font-weight: 600;
    margin-top: 8px;
    margin-bottom: 50px;
    font-size: 14px;
}

.subHeader {
    font-size: 15px;
    color: var(--text-secondary-color);
}

.header {
    font-size: 28px;
    font-weight: 700;
}

.addToCalendarButton {
    font-weight: 600;
    margin-top: 50px;
}

.logo {
    position: absolute;
    bottom: 40px;
    width: 160px;
}

.loading {
    width: 100px;
}