.attendeePopover {
    width: 340px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    row-gap: 20px;
}

.attendeeDivider {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.attendeePopover .header {
    font-weight: 600;
    margin-bottom: 8px;
}

.attendeePopover .basicInfo {
    display: flex;
    align-items: center;
}

.attendeePopover .basicInfoTexts {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}

.attendeePopover .fullname {
    font-weight: 700;
    font-size: 16px;
}

.attendeePopover .title {
    color: var(--dark-black);
    font-weight: 600;
    font-size: 13px;
}

.attendeePopover .bio {
    color: var(--text-secondary-color) !important;
    font-size: 14px;
    height: 40px;
}

.attendeePopover .bioAuto {
    color: var(--text-secondary-color) !important;
    font-size: 14px;
    height: auto !important;
}

.attendeePopover .industryTags {
    display: flex;
    flex-wrap: wrap;
}

.attendeePopover .industryTags>* {
    margin-right: 6px;
    margin-bottom: 6px;
}
