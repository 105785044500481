.container {
    height: 100%;
    display: flex;
    max-width: var(--max-width-default);
    flex-direction: column;
}

.text {
    line-height: 1.4;
}

.companyTitlesContainer {
    width: 100%;
}

.companyHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    position: relative;
}

@media (max-width: 599px) {
    .companyHeaderContainer {
        flex-direction: column;
        text-align: center;
    }
}

.requestIntroButton {
    align-self: flex-end;
    font-weight: 600;
}

@media (max-width: 599px) {
    .requestIntroButton {
        position: static;
        margin-top: 20px;
        width: 100%;
    }
}

.cardBase {
    padding: 30px;
    height: auto;
    color: var(--text-secondary-color);
}

@media (max-width: 599px) {
    .cardBase {
        padding: 20px;
    }
}

.companyLogo {
    margin-right: 20px;
    height: 80px;
    width: 80px;
    object-fit: contain;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
}

@media (max-width: 599px) {
    .companyLogo {
        margin-bottom: 16px;
    }
}

.companyName {
    font-size: 18px;
    font-weight: 600;
}

.companyShortDescription {
    margin-top: 10px;
    line-height: 1.6;
    color: var(--text-secondary-color);
    margin-bottom: 0 !important;
    margin-right: 20px;
    max-width: 700px;
}

.sideBySide {
    display: flex;
}

@media (max-width: 599px) {
    .sideBySide {
        flex-direction: column-reverse;
    }
}

.info {
    composes: cardBase;
    margin-right: 16px;
    overflow: hidden;
    display: grid;
    row-gap: 40px;
}

.generalInfoBox {
    width: 440px;
    height: fit-content;
    flex-shrink: 0;
    margin-left: 20px;
    row-gap: 20px;
    display: grid;
    grid-template-rows: auto;
}

@media (max-width: 599px) {
    .generalInfoBox {
        position: static;
        width: 100%;
        margin-bottom: 20px;
        margin-left: 0px;
    }
}

.generalInfoBox .paragraph {
    margin-bottom: 0;
}

.infoItem {
    display: flex;
    justify-content: space-between;
}

.infoItemTitle {
    color: var(--text-secondary-color);
    font-weight: 600;
}

.infoItemChildren {
    font-weight: 600;
    color: black;
}

.socialContainer {
    display: flex;
    flex-wrap: wrap;
}

.socialContainer>* {
    margin-left: 10px;
}

.socialIcon {
    width: 25px;
    height: 25px;
}

.info .description {
    line-height: 1.5;
    color: var(--text-secondary-color);
}

.subHeader {
    font-weight: 700;
    color: #00063c;
    margin-bottom: 16px;
}

.bigHeader {
    font-weight: 700;
    color: #00063c;
    font-size: 24px;
    margin-bottom: 16px;
}

.industryTags {
    display: flex;
    flex-wrap: wrap;
}

.industryTags>* {
    margin-right: 6px;
    margin-bottom: 6px;
}

.funding {
    composes: cardBase;
    margin-top: 20px;
}

.fundingQubes {
    display: flex;
    flex-wrap: wrap;
}

.fundingQubes>* {
    margin-right: 16px;
}

@media (max-width: 599px) {
    .fundingQubes>* {
        margin-right: 0px;
        margin-bottom: 16px;
    }
}

.cardGrid {
    display: grid;
    row-gap: 40px;
}

.qube {
    border: 1px var(--kinky-grey) solid;
    display: flex;
    width: 180px;
    height: 180px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

@media (max-width: 599px) {
    .qube {
        width: 100%;
    }
}

.qubeTitle {
    margin-top: 20px;
    padding-bottom: 0;
    color: var(--primary);
    font-size: 26px;
    font-weight: 700;
    text-align: center;
}

.qubeSubTitle {
    margin-top: 12px;
    color: #51596c;
    font-size: 14px;
    font-weight: 600;
}

.qubeImg {
    width: 35px;
}

.members {
    margin-top: 60px;
}

@media (max-width: 599px) {
    .members {
        padding-bottom: 48px;
    }
}

.membersContainer {
    margin-top: 28px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.membersContainer>* {
    margin-right: 16px;
    margin-bottom: 16px;
}

@media (max-width: 599px) {
    .membersContainer>* {
        margin-right: 0px;
    }
}

.statusContainer {
    margin-top: 10px;
}

@media (max-width: 599px) {
    .statusContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.statusTag {
    padding: 6px 12px;
    border-radius: 2px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f94453;
    font-size: 12px;
    width: fit-content;
    font-weight: 600;
}

.statusIcon {
    width: 8px;
    margin-right: 8px;
}

.editCompany {
    font-size: 12px;
}