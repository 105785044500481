.container {
    width: 100%;
    max-width: 1400px;
}

.content {
    margin-top: 50px;
    display: grid;
    row-gap: 60px;

}

.conversationGrid {
    padding-top: 24px;
    display: flex;
    flex-direction: row;
    /* overflow-x: auto; */
    flex-wrap: wrap;
}

.conversationGrid > * {
    margin-right: 15px;
    margin-bottom: 15px;
    flex-shrink: 0;
}

@media (max-width: 599px) {
    .conversationGrid > * {
        margin-right: 0px;
    }
    
}
.usersGrid {
    padding-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.usersGrid > * {
    margin-right: 12px;
    margin-bottom: 12px;
}

@media (max-width: 599px) {
    .usersGrid > * {
        margin-right: 0px;
    }
    
}


.emptyCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    min-height: 200px;
    text-align: center;
    height: auto;
    max-width: 1000px;
    color: var(--text-secondary-color);
}

.messagesIcon {
    color: var(--primary);
    font-size: 60px;
}

.emptyCardFollowers {
    /* padding: 20px 20px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    height: auto;
    text-align: center;
    min-height: 200px;
    max-width: 1000px;
    color: var(--text-secondary-color);
}

.emotyStateRandomPeople {
    margin-bottom: 12px;
}

.browseMembersButton {
    margin-top: 20px;
}

.followingYou {
    margin-top: 12px;
    padding: 12px;
    border: 1px solid white;
    width: fit-content;
    background: #50576414;
    font-size: 15px;
    line-height: 1.4;
    color: var(--dark-grey)

}