.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.header { 
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 6px;
}

.subHeader {
    color: var(--text-secondary-color);
    font-size: 16px;
}

.calendarButton {
    margin-top: 20px;
}

.events {
    display: grid;
    margin-top: 20px;
    row-gap: 16px;
    width: 100%;
}


.eventItem {
    position: relative;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(211, 211, 211, 0.5);
    border: 1px var(--kinky-grey) solid;
}


.eventTitle {
    font-weight: 800;
    margin-bottom: 2px;
    font-size: 16px;
    color: var(--dark-black);
}

.eventDate {
    color: var(--text-secondary-color);
    font-size: 14px;
    margin-bottom: 16px;
    color: var(--primary-contrast-dark);
    font-weight: 700;
}

.peopleGoing {
    line-height: 1;
    align-items: flex-start;    
}

.attendToButton {
    position: absolute;
    bottom: 12px;
    right: 12px;
    font-weight: 600;
}

@media (max-width: 599px) {
    .attendToButton {
        margin-top: 18px;
        position: static;
    }
}


.speakersContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.speakers {
    /* margin-top: 14px; */
    display: flex;
    flex-direction: row;
    align-items: center;
}

.speakers>* {
    margin-right: 10px;
}

.attendButton {
    position: absolute;
    right: 12px;
    bottom: 12px;
}

.timerText { 
    margin-top: 20px;
    font-size: 15px;
    color: var(--text-secondary-color);
    text-align: center;
}