.content {
    line-height: 1.4;
    padding-bottom: 20px;
}

.top {
    display: flex;
    background-color: #f7f9fc;
    padding: 30px;
    align-items: center;
}

.texts {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    justify-content: center;
}

.name {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
}

.title {
    font-size: 14px;
    color: var(--text-secondary-color);
}

.bottomPart {
    margin-top: 18px;
    display: grid;
    gap: 20px;
}

.header {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 12px;
}

.overview {
    padding: 12px 30px;
}

.overviewItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media(max-width: 599px) {
    .overviewItems {
        justify-content: flex-start;
    }
    .overviewItems > * {
        margin-right: 24px;
        margin-bottom: 24px; 
    }
}

.membershipItems {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* flex-wrap: wrap;
    justify-content: flex-start; */
    
}

@media (max-width: 599px) {
    .membershipItems {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}


.overviewItemHeader {
    font-size: 14px;
    color: var(--text-secondary-color);
    margin-bottom: 5px;
    /* font-weight: 600; */
}

.overviewItemValue {
    font-weight: 600;
    font-size: 15px;
}

.activity  {
    composes: overview;
    margin-top:-10px;
    max-width: 100vw;
}

.tabs {
    margin-top: 12px;
    height: 280px;
}

.events {
    display: flex;
    flex-direction: column;
    /* row-gap: 12px; */
    overflow-y: scroll;
    height: 200px;
}

.eventItem {
    position: relative;
    /* padding: 12px; */
    width: 100%;
    height: auto;
    cursor: pointer;
    /* border: 1px var(--kinky-grey) solid; */
    /* margin-bottom: 12px; */
}


.eventTitle {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 16px;
    color: var(--dark-black);
}

.eventDate {
    color: var(--text-secondary-color);
    font-size: 14px;
    margin-bottom: 16px;
    /* color: #008e8e; */
    font-weight: 700;
}

.introList {
    display: flex;
    flex-direction: column;
    /* margin: 16px 0px; */
    overflow-y: auto;
    /* padding: 10px 0px; */
    /* height: 320px; */
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.introList::-webkit-scrollbar {
    display: none;
}

.introItem {
    display: flex;
    flex-direction: row;
    width: 100%;
    user-select: none;
    align-items: center;
}

.meetingItem {
    composes: introItem;
    cursor: pointer;
}

.introTitle {
    margin-left: 8px;
    color: var(--soft-black);
    /* font-size: 15px; */
    /* color: var(--text-secondary-color); */
    /* font-weight: 600; */
}

.teamAvatar {
    width: 24px;
    height: 24px;
}

.introStatusTag {
    font-size: 12px !important;
    font-weight: 600;
    margin-left: auto;
}

.introName {
    font-weight: 700;
    color: var(--soft-black);
    /* cursor: pointer; */
}

:global .intro-list .ant-list-item-action {
    margin-left: 8px;
}

.tags {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tags > * {
    margin-right: 8px;
}

.referrerUserContainer {
    margin-top: 8px;
    display: flex;
}

.referrerUserContainer div {
    /* margin-left: 12px; */
    margin-top: -4px;
    font-size: 14px;
    color: var(--darker-black);
    font-weight: 600;
}

.timeline {
    display: flex;
    flex-direction: column;
    /* row-gap: 12px; */
    overflow-y: scroll;
    height: 250px;
    padding: 12px;
}

.timelineItemMessage{
    font-size: 15px;
    /* font-weight: 600; */
    color: var(--dark-black)
}

.timelineItemTime {
    font-size: 12px;
    margin-left: 6px;
    color: var(--text-secondary-color);
}


.timelineIcon {
    font-size: 18px;
    color: var(--primary);
    font-weight: 700;
}