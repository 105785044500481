.container {
    height: auto;
    background: #ffd602;
    padding: 20px 30px;
    color: #2e2a20;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 599px) {
    .container {
        padding: 20px 12px;
    }
}

.header {
    font-size: 20px;
    font-weight: 700;
}

.subHeader  {
    margin-top: 10px;
    font-size: 16px;
    font-size: 400;
    line-height: 1.4;
}

.counterBox {
    width: 80px;
    height: 80px;
    color: white;
    display: flex;
    background-color: #222226;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 28px;
    margin-right: 20px;
    flex-shrink: 0;
}