.container {
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}

.profileImage {
    width: 90px;
    height: 90px;
    background-size: cover;
    background-color: #f5f8fa;
}

.changeButton {
    height: 50px;
    color: white;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 12px;
    opacity: 1;
    background-color: #0000005f;
    text-shadow: 0 -1px 10 rgba(0, 0, 0, 0.12);
     bottom: -25px;
}