.container {
    display: flex;
    flex-direction: row;
}

@media (max-width: 599px) {
    .container {
        flex-direction: column;
    }
    .container > * {
        margin-bottom: 16px;
    }
}

.container > * {
    margin-right: 16px;
}


.endTalkButton {
    background-color: #fe4d4d;
    color: white;
    font-weight: 700;
    border: none;
}

.regularButton {
    color: white;
    border-color: white;
    background-color: transparent;
}

.divider {
    background: #7b7b7e;
    width: 1px;
    margin-right: 20px;
    margin-left: 4px;
}

@media (max-width: 599px) {
    .divider {
        display: none;
    }
}


.sendMessageButton {
    margin-top: 4px;
}

.message {
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
    
}

.messageContainer {
    display: flex;
    padding: 0px 4px 4px 4px;
    font-size: 16px;
    flex-direction: column;
}