
.profileDetails {
    display: flex;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    user-select: none;
}

.profileText {
    margin-left: 12px;
}

.userName {
    font-size: 16px;
    font-weight: 600;
}

.editProfileText {
    color: var(--text-secondary-color);
    font-weight: 600;
    font-size: 13px;
}

.menu {
    background-color: white;
    padding: 12px 0px;
    width: 300px;
    max-width: 300px;
    border-radius: 5px;
    border: 2px #e1e1e1 solid;
    box-shadow: 0 0 10px 0 rgba(198, 198, 198, 0.5);
}

.proMember {
    font-weight: 700;
}

.trialMember {
    font-weight: 700;
}

.proItem {
    width: 100%;
    background-color: var(--primary);
    color: white;
}

.proItem:hover {
    color: white;
}

.avatar {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border: 2px solid var(--primary);
}

.avatarContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    user-select: none;
    cursor: pointer;
}

.badge {
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    font-size: 8px;
    font-weight: 700;
    border-radius: 8px;
    position: absolute;
    color: white;
    bottom: -6px;
    height: 12px;
    width: auto;
    flex-grow: 0;
    text-transform: uppercase;
}