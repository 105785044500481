.container {}

.content {
    padding: 30px 0px 0px 0px;
}

.grid {
    display: grid;
    column-gap: 16px;
    grid-template-columns: 2fr 1.5fr;
}

@media (max-width: 599px) {
    .grid {
        grid-template-columns: 1fr;
    }
}

.metrics {
    /* background-color: red; */
    display: flex;
    flex-direction: column;
}

.charts {
    /* background-color: green; */
}

.sectionHeader {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 12px;
    display: flex;
}

.statsStrip {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 14px;
    margin-bottom: 34px;
}

@media (max-width: 599px) {
    .statsStrip {
        grid-template-columns: 1fr;
    }
}

.viewAll {
    color: var(--text-secondary-color);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 1px;
    margin-left: 15px;
}

.statContainer {
    border: 1px solid var(--kinky-grey);
    padding: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1;
    user-select: none;
}

.statTitle{
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 600;
    color: var(--dark-black);
}

.statsNumbersContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
   
}

.statsBigNumber {
    font-size: 34px;
    color: var(--dark-black);
    font-weight: 700;
}

.statsSideNote{
    color: var(--text-secondary-color);
    font-weight: 600;
    margin-left: 6px;
    margin-bottom: 2px;
}

.bubbleText {
    font-size: 14px;
    background-color: #fbecff;
    padding: 4px 6px;
    color: #a42ece;
    font-weight: 600;
    border-radius: 3px;
    margin-left: auto;
    width: fit-content;
}

.feedbacksDrawer {
    display: grid;
    gap: 18px;
}

.charts {
    padding: 0px 0px;
    display: grid;
    row-gap: 14px;
}

.chart {
    border: 1px solid var(--kinky-grey);
    padding: 14px;
    /* display: flex;
    flex-direction: column; */
}

.chartContainer {
    height: 240px;
    width: 100%;
}

.filterChartHeader {
    display: flex;
    align-items: center;
    font-weight: 700;

}

.filterChartHeader > * {
    margin-left: 8px;
}

.bubblesContainer {
    margin-left: auto;
    display: flex;
    flex-direction: column;
}

.audienceRadioGroup {
    margin-left: auto;
}