.container {
    position: relative;
    padding: 0;
    width: 400px;
    height: 318px;
    cursor: pointer;
    user-select: none;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    transition: ease 0.3s;
    overflow: hidden;
}

@media (max-width: 599px) {
    .container {
        width: 100%;
        height: 348px;
        background-color: white;
    }
}

.themeStrip {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 6px;
    background-color: var(--primary);
}

.content {
    height: 100%;
    padding: 20px 18px 18px 18px;
    display: flex;
    flex-direction: column;
}

.themeTitle {
    font-size: 12px;
    color: var(--primary);
    margin-bottom: 2px;
    font-weight: 600;
}

.title {
    font-size: 19px;
    line-height: 1.6;
    font-weight: bold;
    color: var(--soft-black);
    margin-bottom: 0 !important;
}

.dateContainer {
    display: inline-flex;
    margin-top: 8px;
    /* justify-content: center; */
    align-items: center;
}

.date {
    display: flex;
    align-items: center;
    color: var(--primary-contrast-dark);
    font-size: 14px;
    font-weight: 700;
    /* line-height: 1.2; */
}

.liveBadge {
    margin-left: 12px;
}

.speakersContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.speakersHeader {
    font-size: 12px;
    font-weight: bold;
    color: var(--text-secondary-color);
}

.speakers {
    /* margin-top: 14px; */
    display: flex;
    flex-direction: row;
    align-items: center;
}

.speakers>* {
    margin-right: 10px;
}

.membersGoing {
    margin-top: 8px;
    /* position: absolute; */
    /* bottom: 20px; */
    left: 16px;
    margin-bottom: -4px;
    align-items: flex-start ;
}

.extraSpeakers {
    width: 34px;
    height: 34px;
    border: 1px solid var(--primary);
    background-color: var(--primary-contrast-light);
    border-radius: 50%;
    display: flex;
    font-size: 12px;
    margin-top: -10px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    color: var(--primary);
}

/* forum event styles */

.paddedHeader {
    display: inline;
    font-size: 20px;
    line-height: 1.8;
    font-weight: 700;
    padding: 0.1em 0.5em;
    background-color: white;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
}

.forumDate {
    /* margin-top: 12px; */
    padding: 4px 9px;
    background-color: rgba(255, 255, 255, 0.15);
    width: fit-content;
    color: white;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
}

.forumLiveBadge {
    margin-top: 8px;
    width: fit-content;
}

.endedBadge {
    margin-left: 8px;
    justify-content: center;
    align-items: center;
    padding: 4px 8px 4px 8px;
    font-size: 10px;
    text-transform: capitalize;
    background-color: var(--material-color-grey-900);
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    color: white;
    flex-grow: 0;
    width: auto;
    width: fit-content;
}

.forumMembersGoing {
    /* position: absolute; */
    /* bottom: 20px; */
    align-items: flex-start ;
    color: white ;
    margin-bottom: -4px;
}

.encourager {
    margin-top: 9px;
}

.encouragerLight {
    composes: encourager;
    background-color: var(--primary-contrast-light);
    color: var(--primary-contrast-dark);
}

.hiddenIndicator{
    position: absolute;
    top: 12px;
    text-transform: uppercase;
    border-radius: 5px;
    right: 12px;
    font-size: 10px;
    padding: 4px 8px;
    color: white;
    font-weight: 600;
    background-color: rgba(99, 99, 99, 0.954);
}

.videoIcon {
    margin-right: 4px;
}