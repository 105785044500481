.content {
    color: var(--text-secondary-color);
    font-size: 16px;
    padding: 30px 24px;
    display: grid;
    grid-gap: 60px;
}

@media(max-width: 599px) {
    .content {
        padding: 30px 12px;
    }
}

.uploadText {
    font-size: 14px;
    color: var(--text-secondary-color);
}

:global .resource-form .ant-select-selection-item {
    padding-top: 10px !important;
}

:global .resource-form .ant-select-selection-placeholder {
    padding-top: 10px !important;
}

:global .resource-form textarea {
    min-height: 140px;
}
