.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--background-color);
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    padding-bottom: 120px;
}

@media (max-width: 599px) {
    .content {
        padding: 0 12px;
        padding-bottom: 120px;
    }
}

.header {
    width: 100%;
    margin: 0 auto;
    height: 60px;
    padding: 16px 0px;
}

@media (max-width: 599px) {
    .header {
        /* padding-right: 12px;
        padding-left: 12px; */
    }
}

.headerContent {
    display: flex;
    width: 100%;
    /* background-color: red; */
}

.logoContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logo {
    height: 26px;
}

.guestAccess {
    padding: 4px 8px;
    background-color: var(--primary-contrast-light);
    color: var(--primary-contrast-dark);
    font-size: 14px;
    font-weight: 600;
    margin-left: 12px;
    border-radius: 5px;
    /* color: var(--semi-black) */
}

.header .rightSide {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.logoutButton {
    font-size: 12px;
    margin-left: 8px;
    cursor: pointer;
}

.eventDetails {
    margin-top: 25px;
    display: grid;
    column-gap: 20px;
    grid-template-columns: 60fr 40fr;
}

@media (max-width: 599px) {
    .eventDetails {
        grid-template-columns: 1fr;
    }
}

.eventDetails .leftSide {
    /* background-color: red; */
}

/* @media (max-width:599px) {
    .eventDetails .leftSide {
        display: none;
    }
} */

.eventDetails .rightSide {
    position: sticky;
    top: 20px;
    display: flex;
    width: 100%;
    height: auto;
    margin-top: 0;
    margin-right: 32px;
    padding: 0 0 20px;
    flex-direction: column;
    align-self: flex-start;
    flex: 1;
}

@media (max-width: 599px) {
    .eventDetails .rightSide {
        margin-top: 44px;
        position: static;
    }
}

.eventTitle {
    font-size: 28px;
    line-height: 1.4em;
    font-weight: 700;
}

.themesContainer {
    margin-top: 10px;
}

.eventDescription {
    margin-top: 44px;
    line-height: 1.6;
}

.miniHeader {
    margin-top: 44px;
    font-size: 18px;
    font-weight: bold;
}

.speakersGrid {
    margin-top: 24px;
    display: grid;
    row-gap: 16px;
    column-gap: 16px;
    grid-template-columns: 1fr 1fr;
}

@media (max-width: 599px) {
    .speakersGrid {
        grid-template-columns: 1fr;
    }
}

.dateAndLocationContainer {
    margin-top: 16px;
}

.date>div {
    margin-left: 12px;
}

.icon {
    font-size: 14px;
    color: #727C8B;
}

.location {
    composes: date;
    margin-top: 14px;
}

.dateContainer {
    display: inline-flex;
}

.date {
    display: flex;
    align-items: center;
    color: #727C8B;
    font-weight: 600;
    font-size: 15px;
}

@media (max-width: 599px) {
    .dateContainer {
        display: flex;
        flex-direction: column;
    }
    .date {
        line-height: 1.4;
    }
    .addToCalendar {
        display: flex;
        margin-top: 8px;
    }
}

.guestDisclaimer {
    background-color: white;
    display: flex;
    line-height: 1.6em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--text-secondary-color);
    text-align: center;
    margin-top: 12px;
    padding: 12px;
    border: 1px solid var(--material-color-grey-300);
}

.applyMembershipButton {
    margin-top: 16px;
}

.attendeesContainer {
    margin-top: 50px;
}

@media (max-width: 599px) {
    .attendeesContainer {
        padding-bottom: 48px;
    }
}

.membersContainer {
    margin-top: 28px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.membersContainer>* {
    margin-right: 16px;
    margin-bottom: 16px;
}

@media (max-width: 599px) {
    .membersContainer>* {
        margin-right: 0px;
    }
}

.stickyBottom {
    justify-content: center;
    align-items: center;
    position: fixed;
    display: flex;
    width: 100%;
    bottom: 0px;
    left: 0px;
    height: 94px;
    box-shadow: 0px -4px 10px rgba(211, 211, 211, 0.5);
    background-color: white;
}

.stickyContent {
    /* background-color: red; */
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: center;
}

.stickyContent .rightSide {
    display: flex;
    align-items: center;
    margin-left: auto;
}

@media (max-width: 599px) {
    .stickyContent {
        width: auto;
    }
    .stickyContent .leftSide {
        display: none;
    }
    .stickyContent .rightSide {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0px;
    }
}

.registrationStatus {
    font-weight: 15px;
    font-weight: 600;
    margin-right: 22px;
}

.registerButton {
    border-radius: 10px;
    /* width: 160px; */
    padding: 0px 44px;
    font-size: 18px;
    font-weight: 700;
    height: 50px;
}

.explainerMessage {
    font-weight: 600;
}

.registrationButtonContainer {
    display: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tooltip {
    position: absolute;
    top: -40px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: var(--primary);
    padding: 8px 12px;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgba(211, 211, 211, 0.5);
    background-color: var(--primary-contrast-light);
    /* border: 1px solid rgba(var(--primary), 1); */
}

.inviterText {
    margin-left: 8px;
    font-weight: 700;
}

.titleBottom {
    font-weight: 600;
    margin-top: 6px;
    font-size: 16px;
    max-width: 800px;
    padding: 4px 0px;
    margin-bottom: 0px !important;
}

.dateBottom {
    font-size: 14px;
    color: var(--primary-contrast-dark);
}