.container {
    display: flex;
    flex-direction: column;
}

.titleContainer {
    display: flex;
    align-items: center;
}

.title {
    font-size: 32px;
    font-weight: 700;
    color: var(--headers-color);
}

.linkContainer {
    display: flex;
    align-items: center;
    margin-left: 12px;
}

.link {
    padding: 4px 6px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.337);
    color: var(--material-color-grey-600);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid var(--material-color-grey-300);
    /* box-shadow: 0 4px 20px hsl(0deg 0% 50% / 17%); */
    transition: 0.3s all;
}

.link:hover {
    border-color: var(--primary-contrast-dark);
    color: var(--primary-contrast-dark);
}

@media(max-width: 599px) {
    .titleContainer {
        flex-direction: column;
        align-items: flex-start;
    }
    .title {
        font-size: 24px;
        font-weight: 800;
    }
    .linkContainer {
        margin-top: 12px;
        margin-left: 0px;
    }
}

.subTitle {
    margin-top: 12px;
    line-height: 1.7;
    font-size: 16px;
    color: var(--text-secondary-color);
}

.mediumTitle {
    font-size: 20px;
    font-weight: 700;
}

@media(max-width: 599px) {
    .mediumTitle {
        font-size: 20px;
    }
}

.extraContainer {
    margin-left: 4px;
    font-size: 14px;
    font-weight: 700;
    color: var(--material-color-grey-600);
}